import _ from 'lodash';

const Mapping = {

    publications ( publications ){
        const mappedPublications = _.map( publications, publication => ({
            publicationId: publication.publicationId,
            titre: publication.titre,
            mainImage: publication.mainImage,
            contenu: publication.contenu,
            appercu: publication.appercu,
            nombreDeVue : publication.nombreDeVue
        }));

        return mappedPublications;
    },

    seminars ( seminaires ){
        const mappedSeminars = _.map( seminaires, seminaire => ({
            seminaireId: seminaire.seminaireId,
            theme: seminaire.theme,
            typeFormationId: seminaire.typeFormationId,
            objectif: seminaire.objectif,
            debutInscription: seminaire.debutInscription,
            finInscription: seminaire.finInscription,
            dureeNb: seminaire.dureeNb,
            dureePeriode: seminaire.dureePeriode,
            montant: seminaire.montant,
            monnaieId: seminaire.monnaieId,
            lieuSeminaire: seminaire.lieuSeminaire,
            dureeNb: seminaire.dureeNb,
            dureePeriode: seminaire.dureePeriode,
            montant: seminaire.montant,
            monnaieId: seminaire.monnaieId,
            lieuSeminaire: seminaire.lieuSeminaire,
            adresse: seminaire.adresse,
            codeVille: seminaire.codeVille,
            publicCible: seminaire.publicCible,
            prerequis: seminaire.prerequis,
            autreInfo: seminaire.autreInfo,
            formateurId: seminaire.formateurId,
            codePays: seminaire.codePays,
            secteur: seminaire.secteur,
            domaine: seminaire.domaine,
            dateDebut: seminaire.dateDebut,
            dateFin: seminaire.dateFin,
            ville: seminaire.ville.nom,
            type: seminaire.type

        }));

        return mappedSeminars;
    },

    centres ( centres ){
        const mappedCentres = _.map( centres, centre => (
            {
                centreId: centre.seminaireId,
                codeCentre: centre.codeCentre,
                designation: centre.designation,
                codeVille: centre.codeVille,
                adresse: centre.adresse,
                telephone: centre.telephone,
                responsable: centre.responsable,
                email: centre.email,
                sigle: centre.sigle,
                descriptif: centre.descriptif,
                telephone1: centre.telephone1,
                telephone2: centre.telephone2,
                type: centre.type,
                statut: centre.statut,
                ville: !centre.ville ? {} :{
                  villeId: centre.ville.villeId,
                  codeVille: centre.ville.codeVille,
                  nom: centre.ville.nom,
                  longitude: centre.ville.longitude,
                  latitude: centre.ville.latitude,
                  pays: !centre.ville.pays ? {} : {
                    paysId: centre.ville.pays.paysId,
                    codePays: centre.ville.pays.codePays,
                    nom: centre.ville.pays.nom,
                    longitude: centre.ville.pays.longitude,
                    latitude: centre.ville.pays.latitude
                  },
                  departement: !centre.ville.departement ? {} : {
                    departementId: centre.ville.departement.departementId,
                    codeDepartement: centre.ville.departement.codeDepartement,
                    nom: centre.ville.departement.nom,
                    longitude: centre.ville.departement.longitude,
                    latitude: centre.ville.departement.latitude,
                    pays: !centre.ville.departement.pays ? {} : {
                      paysId: centre.ville.departement.pays.paysId,
                      codePays: centre.ville.departement.pays.codePays,
                      nom: centre.ville.departement.pays.nom,
                      longitude: centre.ville.departement.pays.longitude,
                      latitude: centre.ville.departement.pays.latitude
                    }
                  }
                },
                domaines: !centre.domaines ? [] : centre.domaines.map(domaine => ({
                  domaineId: domaine.domaineId,
                  codeDomaine: domaine.codeDomaine,
                  description: domaine.description,
                  secteur: domaine.secteur ? {
                    secteurId: domaine.secteur.secteurId,
                    codeSecteur: domaine.secteur.codeSecteur,
                    description: domaine.secteur.description
                  } : {}
                }))
              }
        ));

        return mappedCentres;
    },

    departements ( departements ){
        const mappedDepartements = _.map( departements, departement => ({
            id: departement.codeDepartement,
            code: departement.codeDepartement,
            description: departement.nom,
            
        }));
        return mappedDepartements;
    },
    villes ( villes ){
        const mappedVilles = _.map( villes, ville => ({
            id: ville.villeId,
            code: ville.codeVille,
            description: ville.nom,
            departement : ville.departement.codeDepartement
 
        }));
        return mappedVilles;
    },

    
    typesFormations ( typesFormations ){
        
        const mappedTypesFormations = _.map( typesFormations, typesFormation => ({
            id: typesFormation.typeFormationId,
            code: typesFormation.typeFormationId,
            description: typesFormation.description
            
        }));
        return mappedTypesFormations;
    },

    typeCentres ( typeCentres ){        
        const mappedTypeCentres = _.map( typeCentres, typeCentre => ({
            id: typeCentre.typeCentreId,
            code: typeCentre.typeCentreId,
            description: typeCentre.description
            
        }));
        return mappedTypeCentres;
    },

    domaines ( domaines ){
        const mappedDomaines = _.map( domaines, domaine => ({
            id: domaine.domaineId,
            code: domaine.codeDomaine,
            description: domaine.description,
        }));
        return mappedDomaines;
    },

    secteurs ( secteurs ){
        const mappedSecteurs = _.map( secteurs, secteur => ({
            id: secteur.secteurId,
            code: secteur.codeSecteur,
            description: secteur.description,
            
        }));
        return mappedSecteurs;
    },

    somaire ( somaires ){
        return somaires;
    },

    statistiques ( statistiques ){
            return statistiques;
    },
    
}

export default Mapping;