export const statisticsValue = {
  display: "inline-block",
  flexWrap: "wrap",
  padding: "5px",
  justifyContent: "space-between",
  borderBottom: "1px solid #ccc",
  borderRadius: "5px",
  color: "rgb(155, 75, 0)",
  fontSize: "9.6px",
  textAlign: "right",
};

export const statisticsLabel = {
  fontSize: "11.6px",
  display: "inline-block",
};

export const selectorLabel = {
  fontSize: "11.6px",
  padding: "0px",
  paddingLeft: "10px",
  paddingRight: "30px",
  paddingBottom: "2px",
  margin: "0px",
  border: "0px solid #ccc",
  borderBottom: "1px solid #ccc",
  color: "rgb(155, 75, 0)",
  borderRadius: "5px",
  maxWidth: "50%",
  minWidth: "50%",
  textOverflow: "ellipsis",
};

export const dashboardStyle = {
  witdh: "5em",
  background: "white",
  display: "block",
  justifyContent: "space-between",
  marginTop: "16px",
  marginBottom: "16px",
  padding: "16px",
  border: "1px solid #ccc",
  borderRadius: "5px",
  zIndex: "100",
};
