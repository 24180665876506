import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { initFlowbite } from "flowbite";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/logo.jpg";

import Lang from "../../Lang";
import Statistics from "../../Dropdowns/Statistics";
import NavItem from "./NavItem";
function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  const locationHome = (
    <ul
      className="w-full font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row 
        md:space-x-8 md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700 "
    >
      <li>
        <NavItem to="/">{t("header.navbar.home")}</NavItem>
      </li>
      <li>
        <NavItem to="/about">{t("header.navbar.about")}</NavItem>
      </li>
      <li>
        <NavItem
          to="publications"
          className="cursor-pointer md:hover:text-[#ff8c00]"
        >
          {t("header.navbar.publications")}
        </NavItem>
      </li>
      <li>
        <NavItem
          to="statistics"
          className="cursor-pointer md:hover:text-[#ff8c00]"
        >
          {t("header.navbar.statistics")}
        </NavItem>
      </li>
      <li>
        <NavItem
          to="/training-centers"
          className={({ isActive }) =>
            isActive
              ? `text-[#ff8c00] block py-2 pl-3 pr-4 text-xs md:text-sm  rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 
                md:hover:text-[#ff8c00] md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white
                md:dark:hover:bg-transparent`
              : `block py-2 pl-3 pr-4 text-xs md:text-sm  text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 
                md:hover:text-[#ff8c00] md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white
                md:dark:hover:bg-transparent`
          }
        >
          {t("header.navbar.seminars-and-training")}
        </NavItem>
      </li>
    </ul>
  );

  const otherLocations = (
    <ul
      className="font-medium flex flex-col p-4 lg:p-0 mt-4 border border-gray-100 rounded-lg lg:flex-row 
        lg:space-x-8 lg:mt-0 lg:border-0 dark:bg-gray-800 lg:dark:bg-gray-900 dark:border-gray-700"
    >
      <li>
        <NavItem to="/">{t("header.navbar.home")}</NavItem>
      </li>
      <li>
        <NavItem to="/about">{t("header.navbar.about")}</NavItem>
      </li>
      <li>
        <NavItem to="/publications">{t("header.navbar.publications")}</NavItem>
      </li>
      <li className="relative">
        <Statistics />
      </li>
      <li>
        <NavItem to="/seminarsTraining">
          {t("header.navbar.seminars-and-training")}
        </NavItem>
      </li>

      <li>
        <NavItem to="/training-centers">
          {t("header.navbar.trainingCenter")}
        </NavItem>
      </li>
    </ul>
  );
  useEffect(() => {
    initFlowbite();
  }, []);
  return (
    <div>
      <nav className="dark:bg-gray-900 w-11/12 mx-auto">
        <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-2">
          <Link to="/" className="flex items-center justify-between w-full">
            <div className="flex">
              <img src={logo} class="h-10 lg:h-20 mr-3" alt="MAST Logo" />
              <div className="flex flex-col">
                <div>
                  <span className="text-2xl lg:text-4xl font-bold dark:text-white">
                    SIMT
                  </span>
                </div>
                <div>
                  <span className="text-sm lg:text-md dark:text-white">
                    Système d’Information sur le Marché du Travail
                  </span>
                </div>
              </div>
            </div>
            <div
              role="img"
              aria-label="toogle-navigation"
              data-collapse-toggle="navbar-default"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-default"
              aria-expanded="false"
            >
              <span className="sr-only"></span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </div>
          </Link>

          {/* <Lang /> */}
          <div
            className="hidden w-full lg:flex justify-end ml-16 mt-6 mb-6"
            id="navbar-default"
          >
            {/* {location.pathname === "/" ? (
                        locationHome
                    ) : (
                        otherLocations
                    )} */}

            {otherLocations}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
