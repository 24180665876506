import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Queries from '../../../utils/queries';
import { truncateText } from '../../../utils/texts/truncate';
import { getUrl } from '../../../utils/images'
import DOMPurify from 'dompurify';

function ReadPublicationComp() {

  const [publication, setPublication] = useState(null);
  const params = useParams();
  let data = null;
  if (publication) {
    data = publication.contenu;
    // data =  truncateText(contenuHTML, 80);
  }
  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(data)
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Queries.publication(params.publicationId);
        setPublication(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données de publication', error);
      }
    };

    fetchData();
  }, [params.publicationId]);

  if (!publication) {
    return <div>Loading...</div>; 
  }

  return (
    <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <div>
        <img className="w-full max-h-[29rem] rounded-t-lg" src={getUrl(publication.mainImage)} alt="" />
      </div>
      <div className="p-5">
        <div>
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{publication.titre}</h5>
        </div>
        <p className="text-sm text-gray-600 text-justify" dangerouslySetInnerHTML={sanitizedData()}></p>
      </div>
    </div>
  );
}

export default ReadPublicationComp;
