import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faFilterActiveIcon,
} from "@fortawesome/free-solid-svg-icons";
import heroImage from "../../assets/images/background.jpg";
import SomaireStatistic from "./SomaireStatistics";
import RepGeoEmploi from "./RepGeoEmploi";
import RepEmploiSecteur from "./RepEmploiSecteur";
import RepGeoFormation from "./RepGeoFormation";
import Error from "../Error";
import RepEmploiDomaine from "./RepEmploiDomaine";
import StatisticsResume from "./StatisticsResume";
import RepMainOeuvreZone from "./RepMainOeuvreZone";
import RepMainOeuvreCompetence from "./RepMainOeuvreCompetence";
import SalMoyenCompetences from "./SalMoyenCompetences";
import RepEmploiProfession from "./RepEmploiProfession";
import StatisticsFilter from "./StatisticsFilter";
import Mapping from "../../utils/mapping";
import Queries from "../../utils/queries";
import useRouteLocation from "../../hooks/useRouteLocation/index";

function StatisticsSidebar(props) {
  const isPC = window.matchMedia("(min-width: 960px)").matches;
  const isFirstFiltered =
    props.selectedDepartement ||
    props.selectedVille ||
    props.selectedDomaine ||
    props.selectedSecteur;

  const [showSideBar, setShowSideBar] = useState(isPC);
  const [isFiltered, setIsFiltered] = useState(isFirstFiltered);

  const toggleSideBar = () => {
    setShowSideBar((state) => !state);
  };

  return (
    <>
      <button
        onClick={toggleSideBar}
        className="col-span-4 flex lg:hidden justify-end ml-8 mt-2 pl-2"
      >
        Filtrer
        <FontAwesomeIcon
          icon={faFilter}
          style={isFiltered ? { color: "green" } : { color: "brown" }}
        />
      </button>

      <div
        className={`${showSideBar ? "" : "hidden"} pl-2 pr-2 lg:pl-10 lg:pr-4`}
      >
        <SomaireStatistic />
        <StatisticsFilter {...props} />
      </div>
    </>
  );
}

function StatisticsComp() {
  /** Etats pour les filtres */
  const {
    selectedDepartement,
    setSelectedDepartement,
    selectedVille,
    setSelectedVille,
    selectedDomaine,
    setSelectedDomaine,
    selectedSecteur,
    setSelectedSecteur,
  } = useRouteLocation();

  const [listeStatistiques, setListStatistiques] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  function handleDepartmentChange(codeDepartement) {
    setSelectedVille("");
    setSelectedDepartement(codeDepartement);
  }

  function handleVilleChange(codeVille) {
    setSelectedVille(codeVille);
  }

  function handleSecteurChange(codeSecteur) {
    setSelectedSecteur(codeSecteur);
  }

  function handleDomaineChange(codeDomaine) {
    setSelectedDomaine(codeDomaine);
  }

  useEffect(() => {
    setIsLoading(true);
    const datas = async () => {
      try {
        const responseStatistiques = await Queries.statistiques(
          selectedDepartement,
          selectedVille,
          selectedDomaine,
          selectedSecteur
        );
        setListStatistiques(Mapping.statistiques(responseStatistiques.data));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setHasError(true);
      }
    };
    datas();
  }, [selectedDepartement, selectedVille, selectedDomaine, selectedSecteur]);

  const props = { statistiques: listeStatistiques, isLoading: isLoading };

  return (
    <main>
      <div
        className="bg-cover bg-center bg-no-repeat w-full  bg-white-500"
        style={{
          backgroundImage: `url(${heroImage})`,
          backgroundSize: "cover",
          overflow: "hidden",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          backgroundBlendMode: "multiply",
        }}
      >
        <h1 className="text-yellow-800 text-lg md:text-xl  lg:text-4l font-bold text-center h-full bg-gray-50/50 pt-2 pb-2 md:pt-4 md:pb-4 lg:pt-8 lg:pb-8">
          Statistiques sur le marché du travail
        </h1>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4">
        <div className="pt-8 pl-10 pr-4">
          {
            <StatisticsSidebar
              onDepartementChange={handleDepartmentChange}
              onVilleChange={handleVilleChange}
              onSecteurChange={handleSecteurChange}
              onDomaineChange={handleDomaineChange}
              selectedDepartement={selectedDepartement}
              selectedDomaine={selectedDomaine}
              selectedSecteur={selectedSecteur}
              selectedVille={selectedVille}
            />
          }
        </div>
        <div className="col-span-3 pr-10 pl-4">
          <StatisticsResume />
          {isLoading && (
            <p className="text-yellow-800 text-lg md:text-xl  lg:text-4l font-bold text-center bg-gray-50/50 pt-2 pb-2 md:pt-4 md:pb-4 lg:pt-8 lg:pb-8">
              Chargement des dernières statistiques sur le marché du travail ...
            </p>
          )}

          <>
            {hasError ? (
              <Error />
            ) : (
              <section>
                <RepGeoEmploi {...props} />
                <RepEmploiSecteur {...props} />
                <RepEmploiDomaine {...props} />
                <RepGeoFormation {...props} />
                <RepMainOeuvreZone {...props} />
                <RepMainOeuvreCompetence {...props} />
                <SalMoyenCompetences {...props} />
                <RepEmploiProfession {...props} />
              </section>
            )}
          </>

          {/* listeStatistiques.repGeoCentreFormation
        listeStatistiques.repGeoCentreFormationVille */}
        </div>
      </div>
    </main>
  );
}

export default StatisticsComp;
