import React, { useState, useEffect } from "react";
import Title from "../Title";
import DataTable from "../DataTable";
import BarChart from "../charts/BarChart";
import Subtitle from "../Subtitle";
import Loading from "../Loader/index";

const RepMainOeuvreZone = (props) => {
  const dataCity = props.statistiques.repGeoMainOeuvreVille || [];
  const data = dataCity.map(item => [item.label, item.nombre]);
  const offresParDepartement = props.statistiques.repGeoMainOeuvre || [];

   // Extraction des labels dans un tableau
   const labelsDepartment = offresParDepartement.map((item) => item.label);

   // Extraction des nombres dans un tableau
   const nombresDepartment = offresParDepartement.map((item) => item.nombre);
    
    const headers = ['Villes', 'Main d\'oeuvre'];

  
            
  return (
    <>
      <Title name="Repartition Geographique de la main d'oeuvre" />
      <div className="mx-auto grid grid-cols-1 lg:grid-cols-2 pt-5 pb-5 group ">
        {
          props.isLoading
          ? <Loading />
          :
          <>
           <div className="flex flex-col items-center mr-8">
          <DataTable data = {data} headers={headers} />
        </div>
        <div className="w-full object-cover rounded-lg ml-8">
          <Subtitle name="Répartition des mains d'euvre par département"/>
          <BarChart type="bar" legends={false} position="left"   labels={labelsDepartment}  data={nombresDepartment} />
        </div>
          </>
        }
       
      </div>
    </>
  );
};

export default RepMainOeuvreZone;
