import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../../components/Header/Navbar";
import Footer from "../../../components/Footer";
import Scroll from "../../../components/Scroll";

function Root() {
  return (
    <div>
      <Header />
      <Scroll>
      <Outlet />
      </Scroll>
      <Footer />
    </div>
  );
}

export default Root;
