import React, { useState, useEffect, useCallback } from "react";
import Queries from "../../utils/queries";
import Mapping from "../../utils/mapping";
import back from "../../assets/images/back.webp";

const StatisticsResume = () => {
  const [somaire, setSomaire] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSomaireData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await Queries.somaire();
      const mappedData = Mapping.somaire(response.data);
      setSomaire(mappedData);
    } catch (err) {
      setError(err.message);
      console.error("Fetch Somaire Error:", err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSomaireData();
  }, [fetchSomaireData]);

  return (
    <section className="w-full mx-auto grid grid-cols-1 xl:grid-cols-2 group place-items-center">
      <div className="flex flex-col items-center">
        <div className="p-1 md:p-4 lg:p-5 text-justify text-xs md:text-sm lg:text-md">
          <article class="summary-container">
            <p>
              Actuellement, le SIMT (Système d'Information sur le Marché du
              Travail) répertorie{" "}
              <b> {somaire.emploiDisponible} offres d'emploi </b>
              dans la région, provenant de{" "}
              <b>{somaire.emploiDisponible} employeurs</b>, couvrant un large
              éventail de secteurs et de domaines d'activité. Cette diversité
              offre aux{" "}
              <b>{somaire.demandeurDisponible} chercheurs d'emploi </b>
              une variété d'opportunités professionnelles pour répondre à leurs
              compétences et intérêts. Le département le plus prolifique en
              termes d'opportunités d'emploi est{" "}
              <b> le département: {somaire.leaderDepartement} </b>. Il se
              positionne comme un acteur clé dans le marché du travail, offrant
              des perspectives variées aux candidats en quête d'épanouissement
              professionnel.En matière de{" "}
              <b>
                {" "}
                commune, <b> {somaire.leaderCommune} </b>,{" "}
              </b>
              se démarque avec . Concernant les domaines d'activité{" "}
              <b>
                le domaine {somaire.leaderDomaine} et le secteur de{" "}
                {somaire.leaderSecteur}{" "}
              </b>{" "}
              se distinguent, offrant des perspectives prometteuses pour ceux
              cherchant à s'épanouir dans ce domaine dynamique.
            </p>
            <p>
              Le salaire minimum actuel est de <b> {somaire.salaireMinimum} </b>
              et le salaire Maximum de <b> {somaire.salaireMaximum} </b> Pour{" "}
              <b>un salaire moyen de {somaire.salaireMoyen} </b> . Cette
              information est cruciale pour les candidats évaluant les
              opportunités en fonction de la rémunération proposée. Le nombre de
              centres de formations répertoriés est de{" "}
              <b> {somaire.nbreCentreFormations} </b>. Cela témoigne de
              l'importance accordée à la formation continue pour le
              développement des compétences professionnelles.
            </p>
            <br />
            <strong>NB: Les salaires sont indiqués en Gourdes</strong>
          </article>
        </div>
      </div>
      <div className="w-full object-cover">
        <img
          className="object-contain rounded-lg"
          src={back}
          alt="Modern building architecture"
        />
      </div>
    </section>
  );
};

export default StatisticsResume;
