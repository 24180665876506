import { useTranslation } from "react-i18next";
import Aside, { asideConfiguration } from "../../../Aside";

export default function ({ publications }) {
  let publicationsSorted = [...publications]
    .sort((a, b) => b.nombreDeVue - a.nombreDeVue)
    .slice(0, 5);
  const { t } = useTranslation();
  return (
    <>
      <Aside
        items={publicationsSorted}
        title={t("page.publications.popular")}
        config={
          new asideConfiguration(
            "titre",
            "appercu",
            "mainImage",
            "publicationId"
          )
        }
      />
    </>
  );
}
