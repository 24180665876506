import React from "react";
import { Link } from "react-router-dom";
import { getUrl } from "../../../utils/images/index";

function HorizontalCard({ title, description, image, to }) {
  return (
    <Link
      to={to}
      className="lg:w-full flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row lg:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
    >
      <img
        className="object-cover w-4/10 rounded-t-lg h-30 lg:h-16 md:h-10 md:rounded-none md:rounded-l-lg"
        src={getUrl(image)}
        alt=""
      />
      <div className="flex flex-col justify-between leading-normal">
        <p className="mb-2 ml-2 text-xs lg:text-sm  font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </p>
        <p className="mb-3 ml-2 mr-2 font-normal text-xs text-gray-700 dark:text-gray-400 text-justify">
          {description}
        </p>
      </div>
    </Link>
  );
}

export default HorizontalCard;
