import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import back from "../../../assets/images/backwork.jpg";
import Title from "../../Title";
import VoirPlusButton from "../../VoirPlusButton";
import Queries from "../../../utils/queries";
import { useTranslation } from "react-i18next";

function Statistics() {
  const [statistics, setStatistics] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    async function getStatistics() {
      const statistics = await Queries.somaire();
      setStatistics(statistics?.data || {});
    }

    getStatistics();
  }, []);

  function tauxChomage() {
    return (
      (statistics.demandeurDisponible * 100) /
      (statistics.employe + statistics.demandeurDisponible)
    );
  }

  return (
    <section
      role="region"
      id="statistics"
      aria-label="sommaire de statistiques sur le marche du travail"
      className="h-auto lg:h-82 p-5 "
      style={{
        backgroundImage: `url(${back})`,
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        backgroundBlendMode: "multiply",
      }}
    >
      <div className="w-10/12 mx-auto grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 pt-5 pb-5 group place-items-center">
        <div className="flex flex-col items-left w-full bg-gray-50/50 pt-8 pb-8 rounded-lg shadow">
          <Title name={t("page.home.statistics")} />
          <div className="p-5 lg:p-10 text-justify">
            <div className="w-full h-auto grid grid-cols-1 lg:grid-cols-2 gap-4 lg:max-w-lg p-6 dark:bg-gray-800 dark:border-gray-700">
              <div className="">
                <div className="font-bold uppercase">
                  {t("page.home.unemployed")}
                </div>
                <div className="flex mt-2">
                  <span className="">
                    {" "}
                    {isNaN(tauxChomage())
                      ? "-"
                      : tauxChomage().toFixed(2) + "%"}
                  </span>
                </div>
              </div>
              <div className="">
                <div className="font-bold uppercase">
                  {t("page.home.worker")}
                </div>
                <div className="flex mt-2">
                  <span className="">{statistics.employe || "-"}</span>
                </div>
              </div>
              <div className="">
                <div className="font-bold uppercase">
                  {t("page.home.average")}
                </div>
                <div className="flex mt-2">
                  <span className="">{statistics.mSalaire || "-"} HTG</span>
                </div>
              </div>
              <div className="">
                <div className="font-bold uppercase">{t("page.home.job")}</div>
                <div className="flex mt-2">
                  <span className="">{statistics.emploiDisponible || "-"}</span>
                </div>
              </div>
              <div className="">
                <div className="font-bold uppercase">
                  {t("page.home.centers")}
                </div>
                <div className="flex mt-2">
                  <span className="">
                    {statistics.nbreCentreFormations || "-"}
                  </span>
                </div>
              </div>
              <div className="">
                <div className="font-bold uppercase">
                  {t("page.home.seminars")}
                </div>
                <div className="flex mt-2">
                  <span className="">{statistics.nombreFormations || "-"}</span>
                </div>
              </div>
            </div>
            <VoirPlusButton link="/statistics" />
          </div>
        </div>
        <div className="h-48 w-full object-cover md:h-96 md:w-full rounded-lg"></div>
      </div>
    </section>
  );
}

export default Statistics;
