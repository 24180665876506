import React from "react";

function Pagination({ onPathClick, totalPage, currentPage }) {
  const arrowClassName =
    "flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white";
  const pathClassName =
    "flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white";
  const paths = Array.from({ length: totalPage }, (_, index) => index + 1);
  return (
    <nav aria-label="Page navigation example">
      <ul className="flex items-center -space-x-px h-10 text-base">
        <li>
          <button
            disabled={currentPage === 0}
            onClick={() => {
              onPathClick(currentPage - 1);
            }}
            className={arrowClassName}
          >
            <span className="sr-only">Previous</span>
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 1 1 5l4 4"
              />
            </svg>
          </button>
        </li>
        {paths.map((e, i) => (
          <li>
            <button
              onClick={() => {
                onPathClick(i);
              }}
              className={
                `${pathClassName}` +
                (currentPage == i ? " border-blue-300" : "border-gray-300")
              }
            >
              {i + 1}
            </button>
          </li>
        ))}
        <li>
          <button
            disabled={currentPage === totalPage - 1}
            onClick={() => {
              onPathClick(currentPage + 1);
            }}
            className={arrowClassName}
            style={{ transform: "rotate(180deg)" }}
          >
            <span className="sr-only">Next</span>
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 1 1 5l4 4"
              />
            </svg>
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
