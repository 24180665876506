import React from "react";
import PublicationsComp from "../../components/Publications";

function Publications() {
  return (
    <div>
      <PublicationsComp />
    </div>
  );
}

export default Publications;
