import React, { useId } from "react";
import { Link } from "react-router-dom";
import { truncateText } from "../../../../utils/texts/truncate";
import { getUrl } from "../../../../utils/images";
import DOMPurify from "dompurify";
import blankImage from "../../../../assets/images/blank.png";

function VerticalCard({ publication, description }) {
  const id = useId();
  const contenuHTML = publication.appercu;
  const data = truncateText(contenuHTML, 80);

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(data),
  });

  return (
    <article
      lang="fr"
      aria-labelledby={id}
      aria-description={description}
      className="h-72"
    >
      <Link to={`/publications/${publication.publicationId}`} tabIndex={0}>
        <div className="max-w-sm border-b-4 border-b-orange-400 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 lg:h-[260px] sm: h-[300px] transform transition-all hover:scale-95">
          <figure className="h-3/6">
            <img
              className="rounded-t-lg  h-full mx-auto"
              src={
                publication.mainImage
                  ? getUrl(publication.mainImage)
                  : blankImage
              }
              alt={publication.titre}
            />
          </figure>

          <div className="p-3">
            <p
              className="mb-2 text-sm font-bold tracking-tight text-gray-900 dark:text-white"
              id={id}
            >
              {truncateText(publication.titre, 30)}
            </p>
            <p
              className="mb-3 font-normal text-xs text-gray-700 dark:text-gray-400"
              aria-readonly={false}
              dangerouslySetInnerHTML={sanitizedData()}
            ></p>
          </div>
        </div>
      </Link>
    </article>
  );
}

export default VerticalCard;
