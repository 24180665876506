import { useEffect } from 'react';
import { animateScroll as scroll }from 'react-scroll';
import { useLocation } from 'react-router-dom';

function Scroll ({children}){
    const pathname = useLocation();
    useEffect(()=>{
        scroll.scrollToTop();
    }, [pathname])

    return (
        <>
            {children}
        </>
    )
} 

export default Scroll;