import React from "react";
import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const Label = styled.span`
  display: inline-block;
  width: 150px; /* Ajustez cette largeur en fonction de votre besoin */
  font-weight: bold;
`;

const Value = styled.span`
  display: inline;
`;
function DomaineItem({ domaine }) {
  return <span>{domaine.description}</span>;
}

function DomainesList({ domaines }) {
  return (
    <span>
      {domaines.map((domaine) => (
        <DomaineItem key={domaine.domaineId} domaine={domaine} />
      ))}
    </span>
  );
}

function TableCard({ center, toggleFilter }) {
  const [openModal, setOpenModal] = useState(false);
  function formatDate(inputDateString) {
    const inputDate = new Date(inputDateString);

    if (isNaN(inputDate)) {
      return "Invalid Date";
    }

    const day = inputDate.getDate().toString().padStart(2, "0");
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are zero-based, so we add 1.
    const year = inputDate.getFullYear();

    return `${day}-${month}-${year}`;
  }

  return (
    <>
      {/* </Link> */}
      <tr className="border-b border-black">
        <td className="text-left  border-gray-200 border" colSpan={4}>
          {center.sigle}
        </td>
        <td className="text-left  border-gray-200 border" colSpan={10}>
          {center.designation}
        </td>
        <td className="text-left  border-gray-200 border" colSpan={10}>
          {center.adresse}
        </td>
        <td className="text-left  border-gray-200 border" colSpan={6}>
          {" "}
          {center.telephone1} / {center.telephone1}{" "}
        </td>
        <td className="text-center  border-gray-200 border" colSpan={1}>
          <button
            onClick={() => {
              setOpenModal(true);
              toggleFilter();
            }}
          >
            {" "}
            <FontAwesomeIcon
              icon={faInfoCircle}
              style={{ color: "brown" }}
            />{" "}
          </button>{" "}
        </td>
      </tr>

      <Modal
        show={openModal}
        onClose={() => {
          setOpenModal(false);
          toggleFilter();
        }}
      >
        <Modal.Header title="1">
          {" "}
          {center.designation} {center.sigle && "(" + center.sigle + ")"}
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <Label>Adresse:</Label>
              <Value>{center.adresse} </Value> <br />
              <Label>Téléphone:</Label>
              <Value>
                {center.telephone} / {center.telephone1} / {center.telephone2}{" "}
              </Value>{" "}
              <br />
              <Label>Responsable:</Label>
              <Value>{center.responsable} </Value> <br />
              <Label>Email:</Label>
              <Value>{center.email} </Value> <br />
              <Label>Descriptif:</Label>
              <Value>{center.descriptif} </Value> <br />
              <Label>Adresse:</Label>
              <Value>{center.adresse} </Value> <br />
              <Label>Domaine:</Label>
              <Value>
                <DomainesList domaines={center.domaines} />
              </Value>{" "}
              <br />
              <Label>Type:</Label>
              <Value>{center.type} </Value> <br />
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setOpenModal(false);
              toggleFilter();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TableCard;
