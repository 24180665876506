import axios from "axios";

const baseURL =
  process.env.REACT_APP_BASE_LD_PATH_API || "https://api.marchedutravail.ht";
//"http://192.168.0.68:8282";

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: { Authorization: "PORTAIL" },
  //   auth: {
  //     username: process.env.REACT_APP_USERNAME,
  //     password: process.env.REACT_APP_PASSWORD
  //   }
});

const Queries = {
  async apropos() {
    const response = await axiosInstance.get("/website-info/25");
    return response;
  },

  async visite(champ) {
    const params = {
      champ: champ,
    };
    const response = await axiosInstance.get("/visite", { params });
    return response;
  },

  async publications() {
    const response = await axiosInstance.get("/publications");
    return response;
  },

  async publicationsLatest() {
    const response = await axiosInstance.get("/publications/latest");
    return response;
  },

  async publicationsMostViewd(size) {
    const response = await axiosInstance.get(
      `/publications/mostviewed?size=${size}`
    );
    return response;
  },

  async seminars(
    selectedDepartment,
    selectedMunicipality,
    selectedDomain,
    selectedTypeFormations
  ) {
    const params = {
      selectedDepartment: selectedDepartment,
      selectedMunicipality: selectedMunicipality,
      selectedDomain: selectedDomain,
      selectedTypeFormations: selectedTypeFormations,
    };

    const response = await axiosInstance.get(
      "/seminar-training/getSeminarTrainings",
      { params }
    );

    return response;
  },

  async lastSeminars() {
    const response = await axiosInstance.get("/seminar-training/latest");
    return response;
  },

  async trainingCenter(
    selectedDepartment,
    selectedMunicipality,
    selectedDomain,
    selectedType
  ) {
    const params = {
      selectedDepartment: selectedDepartment,
      selectedMunicipality: selectedMunicipality,
      selectedDomain: selectedDomain,
      selectedType: selectedType,
    };
    const response = await axiosInstance.get("/training-center/getByFilter", {
      params,
    });
    return response;
  },
  async lastTrainingCenter() {
    const response = await axiosInstance.get("/training-center/latest");
    return response;
  },

  async departements() {
    const response = await axiosInstance.get("/basicdata/departements");
    return response;
  },
  async villes() {
    const response = await axiosInstance.get("/basicdata/cities");
    return response;
  },
  async secteurs() {
    const response = await axiosInstance.get("/basicdata/sectors");
    return response;
  },

  async TypesFormations() {
    const response = await axiosInstance.get("/basicdata/typeFormations");
    return response;
  },

  async typesCentres() {
    const response = await axiosInstance.get("/basicdata/typeCentres");
    return response;
  },

  async domaines() {
    const response = await axiosInstance.get("/basicdata/domains");
    return response;
  },
  async publication(publicationId) {
    try {
      const response = await axiosInstance.get(
        `/publications/${publicationId}`
      );
      return response;
    } catch (error) {
      console.log("ERROR=== ", error);
    }
  },

  async seminairesformation(seminaireId) {
    try {
      const response = await axiosInstance.get(
        `/seminar-training/${seminaireId}`
      );
      return response;
    } catch (error) {
      console.log("ERROR=== ", error);
    }
  },

  async somaire() {
    try {
      const response = await axiosInstance.get(
        `/statistiques/somairestatistiques/`
      );
      return response;
    } catch (error) {
      console.log("ERROR=== ", error);
    }
  },

  async statistiques(
    selectedDepartment,
    selectedMunicipality,
    selectedDomain,
    selectedSector
  ) {
    const params = {
      selectedDepartment: selectedDepartment,
      selectedMunicipality: selectedMunicipality,
      selectedDomain: selectedDomain,
      selectedSector: selectedSector,
    };

    try {
      const response = await axiosInstance.get(
        `/statistiques/touteslesstatistiques/`,
        { params }
      );
      return response;
    } catch (error) {
      console.log("ERROR=== ", error);
    }
  },
};

export default Queries;
