import React, { useState, useEffect } from "react";
import Title from "../../Title";
import VoirPlusButton from "../../VoirPlusButton";
import { List, Table } from "../../Center/list";
import { useTranslation } from "react-i18next";
import Error from "../../Error/index";

function Center(props) {
  const { t } = useTranslation();
  return (
    <section
      role="region"
      aria-labelledby="centers"
      className="max-w-full pl-1 lg:pl-4 pr-1 lg:pr-4 py-4"
    >
      <div className="grid grid-rows-1 lg:grid-cols-5 gap-4 md:grid-cols-5">
        <div className="col-span-5 md:col-span-5 md:p-4">
          <div className="grid grid-cols-1 lg:grid-cols-4">
            <div className="col-span-4 pr-10 pl-4">
              <Title name={t("page.home.centers")} id="centers" />
              {props.centres == null ? (
                <Error
                  titre={t("error.error-request")}
                  indication={t("error.loadingIndication")}
                />
              ) : props.centres.length === 0 ? (
                <p className="text-center">Aucun centre trouvé</p>
              ) : (
                <>
                  <List centres={props.centres} />
                  <div className="w-full flex items-center justify-center">
                    <VoirPlusButton link="/training-centers" />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Center;
