import React, { useState } from "react";
import ReactPaginate from "react-paginate";

import VerticalCard from "./verticalCard";
import TableCard from "./TableCard";

export const List = ({ centres, toggleFilter }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 w-11/12 mx-auto">
      {centres.map((centre, index) => (
        <div key={index}>
          <VerticalCard toggleFilter={toggleFilter} publication={centre} />
        </div>
      ))}
    </div>
  );
};

export const Table = ({ centres, toggleFilter }) => {
  const PER_PAGE = 3; // Adjustable according to your preference
  const [currentPage, setCurrentPage] = useState(0);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(centres.length / PER_PAGE);

  return (
    <>
      <table className="w-full border-gray-200 border table-fixed mt-4 mb-8">
        <thead>
          <tr className="bg-yellow-500/25">
            <th className="text-left  border-gray-200 border" colSpan={4}>
              Sigle
            </th>
            <th className="text-left  border-gray-200 border" colSpan={10}>
              Designation
            </th>
            <th className="text-left  border-gray-200 border" colSpan={10}>
              adresse
            </th>
            <th className="text-left  border-gray-200 border" colSpan={6}>
              Téléphones
            </th>
          </tr>
        </thead>
        <tbody>
          {centres.map((centre, index) => (
            <TableCard
              toggleFilter={toggleFilter}
              key={index}
              center={centre}
              index={index}
            />
          ))}
        </tbody>
      </table>
      <ReactPaginate
        previousLabel={"<- "}
        nextLabel={" ->"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={
          "pagination flex justify-center bg-stone-200 pr-2 pl-2 rounded-md"
        }
        previousLinkClassName={
          "pr-2 pl-2 text-yellow-500 bg-white border rounded-md mr-2"
        }
        nextLinkClassName={
          "pr-2 pl-2 text-yellow-500 bg-white border rounded-md ml-2"
        }
        disabledClassName={"opacity-50 cursor-not-allowed"}
        activeClassName={"bg-orange-100 text-stone-950 pr-2 pl-2 rounded-md"}
      />
    </>
  );
};
