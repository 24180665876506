import React from "react";
import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const Label = styled.span`
  display: inline-block;
  width: 150px; /* Ajustez cette largeur en fonction de votre besoin */
  font-weight: bold;
`;

const Value = styled.span`
  display: inline;
`;

function TableCard({ seminar, toggleFilter }) {
  const [openModal, setOpenModal] = useState(false);
  function formatDate(inputDateString) {
    const inputDate = new Date(inputDateString);

    if (isNaN(inputDate)) {
      return "Invalid Date";
    }

    const day = inputDate.getDate().toString().padStart(2, "0");
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are zero-based, so we add 1.
    const year = inputDate.getFullYear();

    return `${day}-${month}-${year}`;
  }

  return (
    <>
      {/* </Link> */}
      <tr className="border-b border-black">
        <td className="text-left  border-gray-200 border" colSpan={10}>
          {seminar.theme}
        </td>
        <td className="text-left  border-gray-200 border" colSpan={8}>
          {seminar.lieuSeminaire}
        </td>
        <td className="text-left  border-gray-200 border" colSpan={4}>
          {seminar.montant}
        </td>
        <td className="text-left  border-gray-200 border" colSpan={6}>
          {seminar.domaine}
        </td>
        <td className="text-center  border-gray-200 border" colSpan={1}>
          <button
            onClick={() => {
              setOpenModal(false);
              toggleFilter();
            }}
            aria-label="Information"
          >
            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "brown" }} />
          </button>
        </td>
      </tr>

      <Modal
        show={openModal}
        onClose={() => {
          setOpenModal(false);
          toggleFilter();
        }}
      >
        <Modal.Header>
          {seminar.type}: {seminar.theme}
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <dl>
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                <dt>
                  <Label>Inscription:</Label>
                </dt>
                <dd>
                  <Value>
                    Du {formatDate(seminar.debutInscription)} au{" "}
                    {formatDate(seminar.finInscription)}{" "}
                  </Value>
                </dd>{" "}
                <br />
                <dt>
                  <Label>{seminar.type}:</Label>
                </dt>
                <dd>
                  <Value>
                    Du {formatDate(seminar.dateDebut)} au{" "}
                    {formatDate(seminar.dateFin)}, {seminar.dureeNb}{" "}
                    {seminar.dureePeriode},{" "}
                  </Value>
                </dd>{" "}
                <br />
                <dt>
                  <Label>Lieu:</Label>
                </dt>
                <dd>
                  <Value>{seminar.lieuSeminaire} </Value>
                </dd>{" "}
                <br />
                <dt>
                  <Label>Montant:</Label>
                </dt>
                <dd>
                  <Value>
                    {seminar.montant} {seminar.monnaieId}{" "}
                  </Value>
                </dd>{" "}
                <br />
                <dt>
                  <Label>Domaine:</Label>
                </dt>
                <dd>
                  <Value>{seminar.domaine}</Value>
                </dd>{" "}
                <br />
                <dt>
                  <Label>secteur:</Label>
                </dt>
                <dd>
                  <Value>{seminar.secteur}</Value>
                </dd>{" "}
                <br />
                <dt>
                  <Label>Objectif:</Label>
                </dt>
                <dd>
                  <Value>{seminar.objectif} </Value>
                </dd>{" "}
                <br />
                <dt>
                  <Label>Formateur:</Label>
                </dt>
                <dd>
                  <Value>{seminar.formateurId}</Value>
                </dd>{" "}
                <br />
                <dt>
                  <Label>Contact:</Label>
                </dt>
                <dd>
                  <Value>
                    {seminar.telephoneContact}, {seminar.emailContact}
                  </Value>
                </dd>{" "}
                <br />
                <dt>
                  <Label>Prérequis:</Label>
                </dt>
                <dd>
                  <Value>{seminar.prerequis} </Value>
                </dd>{" "}
                <br />
                <dt>
                  <Label>Infos Supplémentaires:</Label>
                </dt>
                <dd>
                  <Value>{seminar.autreInfo}</Value>
                </dd>{" "}
                <br />
              </p>
            </dl>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setOpenModal(false);
              toggleFilter();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TableCard;
