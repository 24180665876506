import { useRef, useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import background from "../../../assets/images/back.webp";
import Title from "../../Title";
import useBasicData from "../../../hooks/useBasicData/index";
import { UsefullList } from "../../Statistics/UsefullList";
import { selectorLabel } from "../../Statistics/SommaireStatisticsStyle";
import { useTranslation } from "react-i18next";

const selectedLabel = {
  Statistiques: {
    text: "texteStatistique",
    chemin: "/statistics",
  },
  "Séminaires et formations": {
    text: "texteSeminaire",
    chemin: "seminarsTraining",
  },
  "Centres de formation": {
    text: "texteInstitutionDeFormation",
    chemin: "/training-centers",
  },
};

function MarketExploration({ info }) {
  const [selectedOption, setSelectedOption] = useState("Statistiques");
  const villeRef = useRef();
  const domaineRef = useRef();
  const { villes, domaines } = useBasicData();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const selectClassName = `block w-full px-4 py-3 text-base text-gray-900 border border-gray-300 rounded-lg 
bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`;

  const handleOptionChange = (event) => {
    const newSelectedOption = event.target.value;
    setSelectedOption(newSelectedOption);
  };

  const handleSearch = () => {
    const ville = villeRef.current.value;
    const domaine = domaineRef.current.value;
    const state = {
      ville: ville.trim() || null,
      domaine: domaine.trim() || null,
    };

    navigate(selectedLabel[selectedOption].chemin, { state });
  };

  return (
    <section
      role="region"
      aria-labelledby="marketexploration"
      className="w-full py-4 px-4 mx-auto max-w-screen-3xl grid gap-8 lg:gap-16  relative"
    >
      <div
        className="flex flex-col items-center justify-around  bg-blue-50 border border-gray-200 rounded-lg shadow md:flex-row w-full dark:border-gray-700 dark:bg-gray-800"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          overflow: "hidden",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          backgroundBlendMode: "multiply",
        }}
      >
        <div className="flex flex-col justify-start p-4 leading-normal w-full">
          <Form
            className=" relative space-y-6 lg:w-1/4 md:w-2/4 w-full lg:ml-10"
            action="#"
          >
            <Title id="marketexploration" name={t("page.home.exploration")} />

            <div>
              <label className="absolute w-0 h-0 overflow-hidden" for="large">
                Champs d'exploration
              </label>
              <select
                id="large"
                className={selectClassName}
                value={selectedOption}
                onChange={handleOptionChange}
              >
                {Object.keys(selectedLabel).map((value) => (
                  <option key={value}>{value}</option>
                ))}
              </select>
            </div>
            <div>
              <div className="max-w-md">
                {selectedLabel[selectedOption]
                  ? info[selectedLabel[selectedOption].text]
                  : null}
              </div>
            </div>

            <div>
              <label
                htmlFor="ville"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {" "}
                Ville
              </label>
              <select
                ref={villeRef}
                id="ville"
                style={{ ...selectorLabel, minWidth: "100%" }}
                className={selectClassName}
              >
                <UsefullList data={villes} id="CODE_VILLE" />
              </select>
            </div>

            <div>
              <label
                htmlFor="domaine"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {" "}
                Domaine
              </label>
              <select
                ref={domaineRef}
                id="domaine"
                style={{ ...selectorLabel, minWidth: "100%" }}
                className={selectClassName}
              >
                <UsefullList data={domaines} id="CODE_DOMAINE" />
              </select>
            </div>
            <button
              type="submit"
              className="w-full focus:outline-none text-white bg-custom-orange hover:bg-custom-orange focus:ring-4 
                        focus:custom-orange font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900"
              onClick={handleSearch}
            >
              {t("page.home.search")}
            </button>
          </Form>
        </div>
      </div>
    </section>
  );
}

export default MarketExploration;
