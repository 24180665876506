import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const VoirPlusButton = (props) => {
  const { t } = useTranslation();
  return (
    <Link
      to={props.link}
      className="inline-flex items-center mt-4 mb-4 px-3 py-2 focus:outline-none text-white bg-custom-orange hover:bg-custom-orange focus:ring-4 
                focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900"
      tabIndex={0} // Ajout de la gestion du focus clavier
    >
      {t("page.home.more")}
      <svg
        className="w-3.5 h-3.5 ml-2"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 10"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1 5h12m0 0L9 1m4 4L9 9"
        />
      </svg>
    </Link>
  );
};

export default VoirPlusButton;
