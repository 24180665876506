import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import freeFormation from "../../../../assets/images/freeFormation.png";
import paidFormation from "../../../../assets/images/paidFormation.png";
import freeSeminar from "../../../../assets/images/freeSeminar.png";
import paidSeminar from "../../../../assets/images/paidSeminar.png";
import styled from "styled-components";

const Label = styled.span`
  display: inline-block;
  width: 150px;
  font-weight: bold;
`;

const Value = styled.span`
  display: inline;
`;

function VerticalCard({ publication, toggleFilter }) {
  const [openModal, setOpenModal] = useState(false);

  const formatDate = (inputDateString) => {
    const inputDate = new Date(inputDateString);

    if (isNaN(inputDate)) {
      return "Invalid Date";
    }

    const day = inputDate.getDate().toString().padStart(2, "0");
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const year = inputDate.getFullYear();

    return `${day}-${month}-${year}`;
  };

  return (
    <article>
      <button
        onClick={() => {
          setOpenModal(true);
          toggleFilter();
        }}
        className="max-w-sm border-b-4 border-b-orange-400 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 lg:h-[300px] sm:h-[330px] transform transition-all hover:scale-95"
        // Rendre l'élément focusable
        name="link"
        aria-label={`Ouvrir les détails de ${publication.theme}`} // Ajouter une description à des fins d'accessibilité
      >
        {" "}
        <img
          className="rounded-t-lg"
          src={
            publication.type === "FORMATION" && publication.montant > 0
              ? paidFormation
              : publication.type === "SEMINAIRE" && publication.montant > 0
              ? paidSeminar
              : publication.type === "FORMATION" && publication.montant === 0
              ? freeFormation
              : freeSeminar
          }
          alt={publication.theme} // Ajouter une description alternative pour l'image
        />
        <div className="p-3">
          <p className="mb-2 text-sm font-bold tracking-tight text-gray-900 dark:text-white">
            {publication.theme}
          </p>

          <p className="mb-3 font-normal text-xs text-gray-700 dark:text-gray-400">
            <b>Inscription:</b> {formatDate(publication.debutInscription)} au{" "}
            {formatDate(publication.finInscription)} <br />
            <b>{publication.type}:</b> {formatDate(publication.dateDebut)} au{" "}
            {formatDate(publication.dateFin)} <br />
            <b>Lieu:</b> {publication.lieuSeminaire} <br />
            <b>Montant:</b> {publication.montant} {publication.monnaieId}
          </p>
        </div>
      </button>

      <Modal
        show={openModal}
        onClose={() => {
          setOpenModal(false);
          toggleFilter();
        }}
      >
        <Modal.Header>
          {publication.type}: {publication.theme}
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <dt>
                <Label>Inscription:</Label>
              </dt>
              <dd>
                <Value>
                  Du {formatDate(publication.debutInscription)} au{" "}
                  {formatDate(publication.finInscription)}{" "}
                </Value>
              </dd>{" "}
              <br />
              <dt>
                <Label>{publication.type}:</Label>
              </dt>
              <dd>
                <Value>
                  Du {formatDate(publication.dateDebut)} au{" "}
                  {formatDate(publication.dateFin)}, {publication.dureeNb}{" "}
                  {publication.dureePeriode},{" "}
                </Value>
              </dd>{" "}
              <br />
              <dt>
                <Label>Lieu:</Label>
              </dt>
              <dd>
                <Value>{publication.lieuSeminaire} </Value>
              </dd>{" "}
              <br />
              <dt>
                <Label>Montant:</Label>
              </dt>
              <dd>
                <Value>
                  {publication.montant} {publication.monnaieId}{" "}
                </Value>
              </dd>{" "}
              <br />
              <dt>
                <Label>Domaine:</Label>
              </dt>
              <dd>
                <Value>{publication.domaine}</Value>
              </dd>{" "}
              <br />
              <dt>
                <Label>secteur:</Label>
              </dt>
              <dd>
                <Value>{publication.secteur}</Value>
              </dd>{" "}
              <br />
              <dt>
                <Label>Objectif:</Label>
              </dt>
              <dd>
                <Value>{publication.objectif} </Value>
              </dd>{" "}
              <br />
              <dt>
                <Label>Formateur:</Label>
              </dt>
              <dd>
                <Value>{publication.formateurId}</Value>
              </dd>{" "}
              <br />
              <dt>
                <Label>Contact:</Label>
              </dt>
              <dd>
                <Value>
                  {publication.telephoneContact}, {publication.emailContact}
                </Value>
              </dd>{" "}
              <br />
              <dt>
                <Label>Prérequis:</Label>
              </dt>
              <dd>
                <Value>{publication.prerequis} </Value>
              </dd>{" "}
              <br />
              <dt>
                <Label>Infos Supplémentaires:</Label>
              </dt>
              <dd>
                <Value>{publication.autreInfo}</Value>
              </dd>{" "}
              <br />
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setOpenModal(false);
              toggleFilter();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </article>
  );
}

export default VerticalCard;
