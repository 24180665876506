import React, { useEffect, useState } from "react";
import List from "./list";
import MostViewed from "./mostViewed/fromProperty";
import Pagination from "../pagination";
import Queries from "../../utils/queries";
import Mapping from "../../utils/mapping";
import { useTranslation } from "react-i18next";
import heroImage from "../../assets/images/background.jpg";
import Title from "../Title";
import Error from "../Error/index";
import Loading from "../Loader/index";

function PublicationsComp() {
  const [publications, setPublications] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const visited = async () => {
    try {
      const visite = await Queries.visite("publication");
    } catch (error) {}
  };

  useEffect(() => {
    setIsLoading(true);
    const publications = async () => {
      try {
        await visited();
        const response = await Queries.publications();
        const publicationsFetched = Mapping.publications(response.data);
        setPublications(publicationsFetched);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error.response) {
          console.log(`error.response`);
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          setError(t("error.error-request"));
        } else {
          console.log("Error", error.message);
        }
        //   console.log(error.toJSON());
      }
    };
    publications();
  }, []);

  const onPageChange = (index) => {
    setCurrentPage(index);
  };

  const pageLength = 10;
  const content = error ? (
    <Error
      titre={t("error.error-request")}
      indication={t("error.loadingIndication")}
    />
  ) : (
    <>
      <List
        publications={publications.slice(
          currentPage * pageLength,
          pageLength * (currentPage + 1)
        )}
      />
      <div className="lg:pl-8 pt-5 pb-5">
        <Pagination
          totalPage={Math.ceil(publications.length / pageLength)}
          currentPage={currentPage}
          onPathClick={onPageChange}
        />
      </div>
    </>
  );

  return (
    <main className="min-h-screen bg-gray-50">
      <div
        className="bg-cover bg-center bg-no-repeat w-full  bg-white-500"
        style={{
          backgroundImage: `url(${heroImage})`,
          backgroundSize: "cover",
          overflow: "hidden",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          backgroundBlendMode: "multiply",
        }}
      >
        <h1 className="text-yellow-800 text-4xl font-bold text-center h-full bg-gray-50/50 pt-8 pb-8">
          Publications
        </h1>
      </div>

      <div className="max-w-full pl-1 lg:pl-4 pr-1 lg:pr-4 grid grid-rows-1 lg:grid-cols-4 gap-4 md:grid-cols-5">
        <section className="col-span-4 md:col-span-3 md:p-4">
          <Title name={t("page.publications.list")} />
          {isLoading ? <Loading /> : content}
        </section>

        <section className="lg:col-span-1  md:col-span-2 hidden md:flex">
          <MostViewed publications={publications} />
        </section>
      </div>
    </main>
  );
}

export default PublicationsComp;
