import React from "react";

const Title = (props) => {
  return (
    <p
      role={props.role}
      id={props.id}
      className="text-xs md:text-sm lg:text-lg w-full items-center justify-center text-center inline-flex text-green-800 dark:text-gray-800 font-custom font-bold p-2 md:p-3 lg:p-5"
    >
      {props.name}
    </p>
  );
};

export default Title;
