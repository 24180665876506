export function truncateText(text, maxLength, suffix = "...") {
  if (!text) {
    return "...";
  }
  if (text.length <= maxLength) {
    return text;
  } else {
    const truncatedText = text.substring(0, maxLength - suffix.length);
    return truncatedText + suffix;
  }
}
