import React, { useState, useEffect } from "react";
import Aside from "../../components/Aside";
import heroImage from "../../assets/images/background.jpg";
import problem from "../../assets/images/problems.jpg";
import stats from "../../assets/images/stats.jpg";
import logopartenaire from "../../assets/images/logopartenaire.png";
import Title from "../../components/Title";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Queries from "../../utils/queries";
import { useTranslation } from "react-i18next";

function About() {
  const [aboutInfo, setAboutInfo] = useState([]);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const visited = async () => {
      try {
        const visite = await Queries.visite("apropos");
      } catch (error) {}
    };
    visited();
  });

  useEffect(() => {
    const about = async () => {
      try {
        const response = await Queries.apropos();
        setAboutInfo(response.data);
      } catch (error) {}
    };
    about();
  }, []);

  return (
    <main className="min-h-screen">
      <div
        className="bg-cover bg-center bg-no-repeat w-full  bg-white-500"
        style={{
          backgroundImage: `url(${heroImage})`,
          backgroundSize: "cover",
          overflow: "hidden",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          backgroundBlendMode: "multiply",
        }}
      >
        <h1 className="text-yellow-800 text-4xl font-bold text-center h-full bg-gray-50/50 pt-8 pb-8">
          {t("page.home.about")}
        </h1>
      </div>

      <div id="about" className="h-auto p-5">
        <div className="hidden lg:block">
          <div className="w-10/12 mx-auto grid grid-cols-1 lg:grid-cols-2 pt-5 pb-5 group place-items-center">
            <div className="flex flex-col items-center">
              <div className="pr-2 lg:p-10 text-justify">
                <Title name={aboutInfo.aproposTitre1 || ""} />

                <div
                  dangerouslySetInnerHTML={{
                    __html: aboutInfo.aproposParagraphe1,
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col items-center">
              <img
                className="object-contain  md:w-full rounded-lg"
                src={logopartenaire}
                alt="Modern building architecture"
              />
            </div>
          </div>

          <div className="w-10/12 mx-auto grid grid-cols-1 lg:grid-cols-2 pt-5 pb-5 group place-items-center">
            <div className="flex flex-col items-center p-5">
              <img
                className="object-contain  md:w-full rounded-lg"
                src={stats}
                alt="Modern building architecture"
              />
            </div>
            <div className="flex flex-col">
              <Title name={aboutInfo.aproposTitre2 || ""} />
              <div
                dangerouslySetInnerHTML={{
                  __html: aboutInfo.aproposParagraphe2,
                }}
              />
            </div>
          </div>

          <div className="w-10/12 mx-auto grid grid-cols-1 lg:grid-cols-2 pt-5 pb-5 group place-items-center">
            <div className="flex flex-col items-center p-2">
              <Title name={aboutInfo.aproposTitre3} />

              <div
                dangerouslySetInnerHTML={{
                  __html: aboutInfo.aproposParagraphe3,
                }}
              />
            </div>

            <div className="flex flex-col items-center">
              <img
                className="object-contain  md:w-full rounded-lg"
                src={problem}
                alt="Modern building architecture"
              />
            </div>
          </div>
        </div>

        <div className="block lg:hidden">
          <div className="w-10/12 mx-auto grid grid-cols-1 lg:grid-cols-2 pt-5 pb-5 group place-items-center">
            <div className="flex flex-col items-center">
              <img
                className="object-contain  md:w-full rounded-lg"
                src={logopartenaire}
                alt="Modern building architecture"
              />
            </div>
            <div className="flex flex-col items-center">
              <div className="pr-2 lg:p-10 text-justify">
                <Title name={aboutInfo.aproposTitre1 || ""} />
                <div
                  dangerouslySetInnerHTML={{
                    __html: aboutInfo.aproposParagraphe1,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="w-10/12 mx-auto grid grid-cols-1 lg:grid-cols-2 pt-5 pb-5 group place-items-center">
            <div className="flex flex-col items-center p-5">
              <img
                className="object-contain  md:w-full rounded-lg"
                src={stats}
                alt="Modern building architecture"
              />
            </div>

            <div className="flex flex-col">
              <Title name={aboutInfo.aproposTitre2 || ""} />

              <div
                dangerouslySetInnerHTML={{
                  __html: aboutInfo.aproposParagraphe2,
                }}
              />
            </div>
          </div>

          <div className="w-10/12 mx-auto grid grid-cols-1 lg:grid-cols-2 pt-5 pb-5 group place-items-center">
            <div className="flex flex-col items-center">
              <img
                className="object-contain  md:w-full rounded-lg"
                src={problem}
                alt="Modern building architecture"
              />
            </div>

            <div className="flex flex-col items-center p-2">
              <Title name={aboutInfo.aproposTitre3} />
              <div
                dangerouslySetInnerHTML={{
                  __html: aboutInfo.aproposParagraphe3,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default About;
