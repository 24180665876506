import React, { useEffect, useState } from "react";
import { List, Table } from "./list";
import CenterFilter from "./CenterFilter";
import Pagination from "../pagination";
import Queries from "../../utils/queries";
import Mapping from "../../utils/mapping";
import { useTranslation } from "react-i18next";
import heroImage from "../../assets/images/background.jpg";
import Title from "../Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrip, faTable } from "@fortawesome/free-solid-svg-icons";
import Error from "../Error/index";
import useRouteLocation from "../../hooks/useRouteLocation";
import Loading from "../Loader/index";

function CenterComp() {
  const [centres, setCentres] = useState([]);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterVisible, setFilterVisible] = useState(true);

  const {
    selectedDepartement,
    setSelectedDepartement,
    selectedVille,
    setSelectedVille,
    selectedDomaine,
    setSelectedDomaine,
  } = useRouteLocation();
  const [selectedType, setSelectedType] = useState(null);
  const [showTab, setShowTab] = useState(true);

  function toogleShowTab() {
    setShowTab(!showTab);
  }

  function handleDepartmentChange(e) {
    if (typeof e != "string") {
      setSelectedDepartement(e.target.value);
    } else {
      setSelectedDepartement(e);
    }
  }

  function handleVilleChange(e) {
    if (typeof e != "string") {
      setSelectedVille(e.target.value);
    } else {
      setSelectedVille(e);
    }
  }

  function handleTypeChange(e) {
    if (typeof e != "string") {
      setSelectedType(e.target.value);
    } else {
      setSelectedType(e);
    }
  }

  function handleDomaineChange(e) {
    if (typeof e != "string") {
      setSelectedDomaine(e.target.value);
    } else {
      setSelectedDomaine(e);
    }
  }

  function onPageChange(index) {
    setCurrentPage(index);
  }

  useEffect(() => {
    const visited = async () => {
      try {
        const visite = await Queries.visite("centreformation");
      } catch (error) {}
    };
    visited();
  });

  useEffect(() => {
    const TrainingCenter = async () => {
      try {
        setIsLoading(true);
        const response = await Queries.trainingCenter(
          selectedDepartement,
          selectedVille,
          selectedDomaine,
          selectedType
        );

        const centresFetched = Mapping.centres(response.data);
        console.log(response);
        setCentres(centresFetched);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error.response) {
          console.log(`error.response`);

          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          setError(t("error.error-request"));
        } else {
          console.log("Error", error.message);
        }
      }
    };
    TrainingCenter();
  }, [selectedDepartement, selectedVille, selectedDomaine, selectedType]);

  function toggleFilter() {
    setFilterVisible((state) => !state);
  }

  const pageLength = 12;
  const viewedCenters = centres.slice(
    currentPage * pageLength,
    pageLength * (currentPage + 1)
  );

  const content = error ? (
    <Error
      titre="Echec de chargement des donnees"
      indication="Nous rencontrons actuellement un problème lors du chargement des données. Veuillez réessayer plus tard."
    />
  ) : (
    <div className="grid grid-cols-1 lg:grid-cols-4">
      <div className="pt-8 pl-10 pr-4">
        {showTab && (
          <button
            aria-hidden={true}
            tabIndex={-1}
            className="ml-4 mb-4"
            onClick={toogleShowTab}
          >
            {" "}
            <FontAwesomeIcon icon={faTable} style={{ color: "brown" }} />{" "}
          </button>
        )}
        {!showTab && (
          <button
            aria-hidden={true}
            tabIndex={-1}
            className="ml-4 mb-4"
            onClick={toogleShowTab}
          >
            {" "}
            <FontAwesomeIcon icon={faGrip} style={{ color: "brown" }} />{" "}
          </button>
        )}

        <CenterFilter
          onDepartementChange={handleDepartmentChange}
          onVilleChange={handleVilleChange}
          onTypeChange={handleTypeChange}
          onDomaineChange={handleDomaineChange}
          selectedDomaine={selectedDomaine}
          selectedVille={selectedVille}
          selectedDepartement={selectedDepartement}
          selectedType={selectedType}
          isVisible={filterVisible}
        />
      </div>
      <div className="col-span-3 pr-10 pl-4">
        <Title name="Liste des centres de formations" />
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {showTab && (
              <List toggleFilter={toggleFilter} centres={viewedCenters} />
            )}
            {!showTab && (
              <Table toggleFilter={toggleFilter} centres={viewedCenters} />
            )}

            <div className="lg:pl-8 pt-5 pb-5">
              <Pagination
                currentPage={currentPage}
                onPathClick={onPageChange}
                totalPage={Math.ceil(centres.length / pageLength)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );

  return (
    <>
      <main id="main" className="min-h-screen bg-gray-50">
        <div
          className="bg-cover bg-center bg-no-repeat w-full  bg-white-500"
          style={{
            backgroundImage: `url(${heroImage})`,
            backgroundSize: "cover",
            overflow: "hidden",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            backgroundBlendMode: "multiply",
          }}
        >
          <h1 className="text-yellow-800 text-4xl font-bold text-center h-full bg-gray-50/50 pt-8 pb-8">
            Centre de formation
          </h1>
        </div>

        <div className="max-w-full pl-1 lg:pl-4 pr-1 lg:pr-4 grid grid-rows-1 lg:grid-cols-5 gap-4 md:grid-cols-5">
          <div className="col-span-5 md:col-span-5 md:p-4">{content}</div>
        </div>
      </main>
    </>
  );
}

export default CenterComp;
