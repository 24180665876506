import React from "react";

import logopartenaire from "../../../assets/images/logopartenaire.png";
import Title from "../../Title";
import VoirPlusButton from "../../VoirPlusButton";

import { useTranslation } from "react-i18next";

function About({ info }) {
  const { t } = useTranslation();

  return (
    <section className="h-auto lg:h-82 p-5" aria-labelledby="about">
      <div className="w-10/12 mx-auto grid grid-cols-1 lg:grid-cols-2 pt-5 pb-5 group place-items-center">
        <div className="flex flex-col items-center">
          <Title name={t("page.home.about")} id="about" />

          <div className="p-5 lg:p-10 text-justify">
            <div
              dangerouslySetInnerHTML={{
                __html: info.aproposDuSimtAcceuil,
              }}
            />
            <VoirPlusButton link="/about" tabIndex={0} />
          </div>
        </div>
        <div className="h-48 w-full object-cover md:h-96 md:w-full rounded-lg">
          <img
            className="h-48 object-contain md:h-96 md:w-full rounded-lg"
            src={logopartenaire}
            alt="Les partenaires du SIMT (BID, FAES, MAST)"
          />
        </div>
      </div>
    </section>
  );
}

export default About;
