import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-tailwind/react";
import {
  faBuilding,
  faBullseye,
  faCity,
  faMap,
} from "@fortawesome/free-solid-svg-icons";
import Queries from "../../utils/queries/index.js";
import Subtitle from "../Subtitle.js";
import { statisticsLabel, selectorLabel, dashboardStyle } from "../style.js";
import { UsefullList } from "../Statistics/UsefullList.js";
import Mapping from "../../utils/mapping/index.js";

const Filtre = ({
  onDepartementChange,
  onVilleChange,
  onTypeChange,
  onDomaineChange,
  selectedVille,
  selectedDomaine,
  selectedDepartement,
  selectedType,
  isVisible,
}) => {
  const [departements, setDepartments] = useState([]);
  const [villes, setVilles] = useState([]);
  const [domaines, setDomaines] = useState([]);
  const [types, setTypes] = useState([]);
  const [visibleCities, setVisibleCities] = useState([]);
  const [departementFilter, setDepartmentFilter] =
    useState(selectedDepartement);
  const [villeFilter, setVilleFilter] = useState(selectedVille);

  function handleVilleChange(e) {
    const codeVille = e.target.value;
    const codeDepartement = villes.find(
      (v) => v.code == codeVille
    )?.departement;
    onVilleChange(codeVille);
    setDepartmentFilter(codeDepartement);
  }

  function handleDepartmentChange(e) {
    const codeDepartement = e.target.value;
    setVisibleCities(
      villes.filter((v) =>
        codeDepartement ? v.departement == codeDepartement : true
      )
    );
    setDepartmentFilter(codeDepartement);
    onVilleChange("");
    onDepartementChange(codeDepartement);
  }

  useEffect(() => {
    const datas = async () => {
      try {
        const responseDepartment = await Queries.departements();
        const listeDepartments = Mapping.departements(responseDepartment.data);
        setDepartments(listeDepartments);

        const responseVilles = await Queries.villes();
        const listeVilles = Mapping.villes(responseVilles.data);
        setVilles(listeVilles);
        setVisibleCities(listeVilles);

        const responseDomaines = await Queries.domaines();
        const listeDomaines = Mapping.domaines(responseDomaines.data);
        setDomaines(listeDomaines);

        const responseTypes = await Queries.typesCentres();
        const listeTypes = Mapping.typeCentres(responseTypes.data);
        setTypes(listeTypes);
      } catch (error) {
        if (error.response) {
          console.log(`error.response`);
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        } else {
          console.log("Error", error.message);
        }
      }
    };
    datas();
  }, []);

  function reset() {
    setVilleFilter("");
    setDepartmentFilter("");
    onTypeChange("");
    onDomaineChange("");
    onVilleChange("");
    onDepartementChange("");
  }

  return (
    <div
      id="dashboard"
      style={{ ...dashboardStyle, display: isVisible ? "block" : "none" }}
    >
      <div className="w-11/12 mx-auto grid grid-cols-4">
        <div className="col-span-4">
          <Subtitle name="Filtre" />
        </div>
        <span
          id="departement"
          className="col-span-2 pt-2"
          style={statisticsLabel}
        >
          <FontAwesomeIcon icon={faMap} style={{ color: "green" }} />{" "}
          Départements
        </span>
        <select
          style={selectorLabel}
          className="col-span-2"
          onChange={handleDepartmentChange}
          value={departementFilter}
          aria-labelledby="departement"
        >
          <UsefullList data={departements} id="CODE_DEPARTEMENT" />
        </select>

        <span id="ville" className="col-span-2 pt-2" style={statisticsLabel}>
          {" "}
          <FontAwesomeIcon
            icon={faCity}
            style={{ color: "brown" }}
          /> Villes{" "}
        </span>
        <select
          style={selectorLabel}
          className="col-span-2"
          onChange={handleVilleChange}
          value={villeFilter}
          aria-labelledby="ville"
        >
          <UsefullList data={visibleCities} id="CODE_VILLE" />
        </select>

        <span id="domaine" className="col-span-2 pt-2" style={statisticsLabel}>
          {" "}
          <FontAwesomeIcon icon={faBullseye} style={{ color: "red" }} />{" "}
          Domaines{" "}
        </span>
        <select
          style={selectorLabel}
          className="col-span-2 my-1"
          onChange={onDomaineChange}
          aria-labelledby="domaine"
          value={selectedDomaine}
        >
          <option disabled selected>
            {" "}
          </option>
          <UsefullList data={domaines} id="CODE_DOMAINE" />
        </select>

        <span id="type" className="col-span-2 pt-2" style={statisticsLabel}>
          {" "}
          <FontAwesomeIcon
            icon={faBuilding}
            style={{ color: "blue" }}
          /> Types{" "}
        </span>
        <select
          style={selectorLabel}
          className="col-span-2 my-1"
          onChange={onTypeChange}
          value={selectedType}
          aria-labelledby="type"
        >
          <UsefullList data={types} id="CODE_TYPE" />
        </select>

        <span className="col-span-2 pt-2" style={statisticsLabel}></span>

        <Button
          variant="outlined"
          color="blue"
          size="sm"
          className="col-span-2 my-2"
          onClick={reset}
        >
          Reinitialiser
        </Button>
      </div>
    </div>
  );
};

export default Filtre;
