import React, { useState, useEffect } from "react";
import Title from "../Title";
import DataTable from "../DataTable";
import BarChart from "../charts/BarChart";
import Subtitle from "../Subtitle";
import PolarAreaChart from "../charts/PolarAreaChart";
import Loading from "../Loader/index";

const RepGeoFormation = (props) => {
  const dataCity = props.statistiques.repGeoFormationVille || [];
  const data = dataCity
    .filter((item) => !!item)
    .map((item) => [item.label, item.nombre]);
  const offresDept = props.statistiques.repGeoFormation || [];
  const offresParDepartement = offresDept.filter((item) => !!item);

  // Extraction des labels dans un tableau
  const labelsDepartment = offresParDepartement.map((item) => item.label);

  // Extraction des nombres dans un tableau
  const nombresDepartment = offresParDepartement.map((item) => item.nombre);

  const headers = ["Villes", "Nombre de formation"];

  return (
    <>
      <Title name="Repartition Géographique des offres de formations" />
      <div className="mx-auto grid grid-cols-1 lg:grid-cols-2 group rep-formation">
        {props.isLoading ? (
          <Loading />
        ) : (
          <>
            <div>
              <Subtitle name="Répartition des offres de formations par département" />
              <PolarAreaChart
                type="bar"
                legends={false}
                position="left"
                labels={labelsDepartment}
                data={nombresDepartment}
              />
            </div>
            <div>
              <DataTable data={data} headers={headers} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RepGeoFormation;
