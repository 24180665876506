import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Queries from '../../../utils/queries';
import { truncateText } from '../../../utils/texts/truncate';
import { getUrl } from '../../../utils/images'
import DOMPurify from 'dompurify';

function ReadSeminarsTrainingComp() {

  const [publication, setPublication] = useState(null);
  const params = useParams();
  let data = null;
  if (publication) {
    data = publication.contenu;
    // data =  truncateText(contenuHTML, 80);
  }
  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(data)
  })

   const isValidValue = (value) => {
    return value && value.trim() !== '';
  };


  useEffect(() => {
    const fetchData = async () => {
      try {      
        const response = await Queries.seminairesformation(params.seminaireId);
        setPublication(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données de publication', error);
      }
    };

    fetchData();
  }, [params.seminaireId]);

  if (!publication) {
    return <div>Loading...</div>; 
  }

  return (
    <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <div>
        {/* <img className="w-full max-h-[29rem] rounded-t-lg" src={getUrl(publication.mainImage)} alt="" /> */}
      </div>
      <div className="p-5">

        <p> {publication.type} sur le theme "{publication.theme}", {publication.typeFormationId},  pour "{publication.publicCible}",  {publication.dureeNb} {publication.dureePeriode} </p>
        <p> Formation du {publication.dateDebut} au {publication.dateFin} </p>
        <p> Inscription du du {publication.debutInscription} au {publication.finInscription}</p>
        <p> {publication.lieuSeminaire}, {publication.ville.departement.nom}, {publication.ville.pays.nom} </p>
        <p> {publication.montant} {publication.monnaieId}</p>
        <p> <strong>Contact:{publication.telephoneContact}, {publication.emailContact}  </strong>  </p>        
        <p> {publication.domaine}, {publication.secteur} </p>
        <p> Formateur: {publication.formateurId} </p>
        <p> Objectif: {publication.objectif}</p>
        <p> Prérequis:  {publication.prerequis} </p>
        <p> Informations Supplémentaires: {publication.autreInfo} </p>
        
        
        <div>
          <h1 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"></h1>
          <h2 className="mb-2 text-lg tracking-tight text-gray-900 dark:text-white"></h2>
          {publication.centreId}
        </div>
        <div>
       <hr/>
      <hr/>
    </div>
        {/* <p className="text-sm text-gray-600 text-justify" dangerouslySetInnerHTML={sanitizedData()}></p> */}
      </div>
    </div>
  );
}

export default ReadSeminarsTrainingComp;
