import React, { useState, useEffect } from "react";

function DataTable(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [search, setSearch] = useState("");

  const sampleData = props.data;

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  // Filter data based on search
  const filteredData = sampleData.filter((row) => {
    return (
      row[0].toLowerCase().includes(search.toLowerCase()) ||
      row[1].toLowerCase().includes(search.toLowerCase())
    );
  });

  // Pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  // Generate pagination buttons
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  return (
    <div className="p-4">
      {/* <input
                type="text"
                placeholder="Rechercher..."
                value={search}
                onChange={handleSearch}
                className="p-2 border rounded mb-4 w-full text-xs"
            /> */}

      <div className="w-full">
        <table className="w-full table-fixed">
          <thead>
            <tr className="bg-yellow-500/25">
              {props.headers.map((header, index) => (
                <th className="text-left" key={index}>{header}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {paginatedData.map((row, index) => (
              <tr key={index} className="border-b border-gray-300">
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => handleChangePage(number)}
            className={`px-2 py-1 border ${
              currentPage === number ? "bg-gray-200" : ""
            }`}
          >
            {number}
          </button>
        ))}
      </div>
    </div>
  );
}

export default DataTable;
