import React, { useEffect, useState } from "react";
import VoirPlusButton from "../../VoirPlusButton";
import List from "../../Publications/list";
import Error from "../../Error/index";
import Queries from "../../../utils/queries";
import Mapping from "../../../utils/mapping";
import { useTranslation } from "react-i18next";
import Title from "../../Title";

function LastestPublications() {
  const [publications, setPublications] = useState([]);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPublications = async () => {
      try {
        const response = await Queries.publicationsLatest();
        const publicationsFetched = Mapping.publications(response.data);
        setPublications(publicationsFetched);
      } catch (error) {
        setError(t("error.error-request"));
        console.error("Error fetching publications:", error);
      }
    };
    fetchPublications();
  }, []);

  return (
    <section
      role="region"
      className={() => (error ? "bg-gray-50" : "min-h-screen  bg-gray-50")}
      aria-labelledby="latest_articles"
    >
      <div className="max-w-full pl-1 lg:pl-4 pr-1 lg:pr-4 grid grid-rows-1 lg:grid-cols-3 gap-4 md:grid-cols-3">
        <div className="col-span-4 md:col-span-3 md:p-4">
          <Title name={t("page.home.publications")} id="latest_articles" />
          {error ? (
            <Error
              titre={t("error.error-request")}
              indication={t("error.loadingIndication")}
            />
          ) : publications.length == 0 ? (
            <>
              {" "}
              <p className="text-center">Aucune publication trouvé</p>
            </>
          ) : (
            <>
              <List publications={publications} />
              <br />
              <div className="w-full flex items-center justify-center">
                <VoirPlusButton
                  link="/publications"
                  aria-label="Voir plus de publications"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default LastestPublications;
