import { useState, useEffect } from "react";
import Queries from "../../utils/queries/index"
import Mapping from "../../utils/mapping/index"

export default function useBasicData(){
    const [departements, setDepartments] = useState([]);
    const [villes, setVilles] = useState([]);
    const [domaines, setDomaines] = useState([]);
    const [secteurs, setSecteurs] = useState([]);

    useEffect(() => {
        const get = async () => {
          try {
            const responseDepartment = await Queries.departements();
            const listeDepartments = Mapping.departements(responseDepartment.data);
        
            const responseVilles = await Queries.villes();
            const listeVilles = Mapping.villes(responseVilles.data);
           
            const responseDomaines = await Queries.domaines();
            const listeDomaines = Mapping.domaines(responseDomaines.data);
       
    
            const responseSecteurs = await Queries.secteurs();
            const listeSecteurs = Mapping.secteurs(responseSecteurs.data);

            setDepartments(listeDepartments);
            setVilles(listeVilles);
            setDomaines(listeDomaines);
            setSecteurs(listeSecteurs);
    
          } catch (error) {
           console.error(error)
          }
        };
        get();
      }, []);

      return {
        departements, villes, secteurs, domaines
      }
}