import { useEffect, useState } from "react";
import Aside, { asideConfiguration } from "../../../Aside";
import Queries from "../../../../utils/queries";
import Mapping from "../../../../utils/mapping";

export default function () {
  const [publications, setPublications] = useState([]);

  useEffect(() => {
    async function get() {
      try {
        const response = await Queries.publicationsMostViewd(5);
        const publicationsFetched = Mapping.publications(response.data);
        setPublications(publicationsFetched);
      } catch (error) {
        console.error(error);
      }
    }
    get();
  }, []);
  return (
    <>
      <Aside
        items={publications}
        title="Les plus populaires"
        config={
          new asideConfiguration(
            "titre",
            "appercu",
            "mainImage",
            "publicationId"
          )
        }
      />
    </>
  );
}
