import error from "../../error.png";

export default function Error({titre, indication}){
    return (
        <div className="flex flex-col items-center justify-center m-4">
            <h1 className="font-bold">{titre}</h1>
            <h2 className="mb-4">{indication}</h2>
            <img src={error} alt="Image d'erreur" className="mb-4" style={{ width: '100px', height: '100px' }}/>
      </div>
    );
}