import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Queries from "../../utils/queries";
import Mapping from "../../utils/mapping";
import {
  faBolt,
  faBriefcase,
  faFile,
  faIndustry,
  faMoneyBill,
  faMoneyBill1Wave,
  faMoneyBillAlt,
  faSchool,
} from "@fortawesome/free-solid-svg-icons";
import Subtitle from "../Subtitle";
import {
  statisticsValue,
  statisticsLabel,
  dashboardStyle,
} from "./SommaireStatisticsStyle";
import Loading from "../Loader/index";

const StatisticItem = ({ icon, color, label, value }) => (
  <React.Fragment>
    <span className="col-span-3" style={statisticsLabel}>
      <FontAwesomeIcon icon={icon} style={{ color }} /> {label}
    </span>
    <b style={statisticsValue}> {value} </b>
  </React.Fragment>
);

const SomaireStatistic = () => {
  const [somaire, setSomaire] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSomaireData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await Queries.somaire();
      const mappedData = Mapping.somaire(response.data);
      setSomaire(mappedData);
    } catch (err) {
      setError(err.message);
      console.error("Fetch Somaire Error:", err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSomaireData();
  }, [fetchSomaireData]);

  if (loading) return <Loading />;

  return (
    <div style={dashboardStyle}>
      <div className="w-11/12 mx-auto grid grid-cols-4">
        <div className="col-span-4">
          <Subtitle name="Somaire" />
        </div>
        <StatisticItem
          icon={faBriefcase}
          color="blue"
          label="Emploi disponibles"
          value={somaire.emploiDisponible}
        />
        <StatisticItem
          icon={faFile}
          color="red"
          label="Demandeurs d'emploi"
          value={somaire.demandeurDisponible}
        />
        <StatisticItem
          icon={faIndustry}
          color="brown"
          label="Employeurs"
          value={somaire.emploiDisponible}
        />
        <StatisticItem
          icon={faSchool}
          color="orange"
          label="Centre de formations"
          value={somaire.nbreCentreFormations}
        />
        <StatisticItem
          icon={faMoneyBill}
          color="red"
          label="Salaire minimum"
          value={somaire.minimum}
        />
        <StatisticItem
          icon={faMoneyBill1Wave}
          color="green"
          label="Salaire Maximum"
          value={somaire.salMaximum}
        />
        <StatisticItem
          icon={faMoneyBillAlt}
          color="orange"
          label="Salaire Moyen"
          value={somaire.mSalaire}
        />
        <StatisticItem
          icon={faBolt}
          color="orange"
          label="Offres de formation"
          value={somaire.nombreFormations}
        />
      </div>
    </div>
  );
};

export default SomaireStatistic;
