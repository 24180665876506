import React from "react";
import SeminarsComp from "../../components/Seminars";

function SeminarsTraining() {
  return (
    <>
      <SeminarsComp />
    </>
  );
}

export default SeminarsTraining;
