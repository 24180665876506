// import { createStore, combineReducers } from "redux";
import { createSlice, configureStore } from '@reduxjs/toolkit';
import DarkModeSlice from '../redux/slices/darkMode';


const store = configureStore({
    reducer: { 
        darkMode: DarkModeSlice,
    },
});

export default store;