import react, { Children } from "react";

const Subtitle = (props) => {
  return (
    <div className="md:text-md w-full item-left justify-start text-left inline-flex text-custom-orange dark:text-gray-400 font-custom font-bold pb-5 pt-5 ">
      {props.name}
    </div>
  );
};

export default Subtitle;
