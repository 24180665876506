import React from "react";
import VerticalCard from "./verticalCard";

function List({ publications }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 w-11/12 mx-auto">
      {publications.map((publication, index) => (
        <div key={index}>
          <VerticalCard
            publication={publication}
            tabIndex={0} // Ajoute la gestion du focus clavier
            description={`Carte de publication numéro ${index + 1}`} // Ajoute une étiquette explicite
          />
        </div>
      ))}
    </div>
  );
}

export default List;
