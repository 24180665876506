export const UsefullList = (props) => {
  return (
    <>
      <option value=""></option>
      {props.data.map((item) => (
        <option
          key={item.id}
          value={item.code}
          title={item.description}
          className="overflow-hidden text-overflow-ellipsis whitespace-nowrap py-2"
        >
          {item.description}
        </option>
      ))}
    </>
  );
};
