import { createSlice } from '@reduxjs/toolkit';

const getInitialTheme = () => {
    if (typeof window !== 'undefined' && window.localStorage) {
      const storedPrefs = window.localStorage.getItem('current-theme');
      if (typeof storedPrefs === 'string') {
        return storedPrefs;
      }
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return 'dark';
      }
    } 
    return 'light';
  };

  const initialDarkModeState = {
    theme: getInitialTheme(),
  };

const darkModeSlice = createSlice({
    name : 'darkMode',
    initialState: initialDarkModeState,
    reducers : {
        toggle (state) {
            // Inverse state.theme value
            state.theme = state.theme === 'dark' ? 'light' : 'dark';

            // Update class document.documentElement
            if (state.theme === 'dark') {
                document.documentElement.classList.add('dark');
            } else {
                document.documentElement.classList.remove('dark');
            }

            // update localStorage
            localStorage.setItem('current-theme', state.theme);

        },
    }
});
export const darkModeActions = darkModeSlice.actions;
export default darkModeSlice.reducer;