import React, {createContext, useContext, useEffect, useState} from "react";
import Home from "./pages/Home";
import Publications from './pages/Publications';
import Statistics from './pages/Statistics';
import TrainingCenters from "./pages/TrainingCenters";
import About from "./pages/About";
import Root from "./pages/Layout/Root";
import ReadPublication from "./pages/ReadPublication";
import ReadSeminarsTraining from "./pages/ReadSeminarsTraining";
import Error from "./pages/Error";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import SeminarsTraining from "./pages/SeminarsTraining";


const ThemeContexte = createContext();

const ThemeProvider = ({children}) => {
  const [visited, setVisited] = useState(false);

  const isVisited = () =>{
    if(visited==false){
      setVisited(true);
    }
  }
  
}



const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "publications",
        children: [
          {
            index: true,
            element: <Publications />,
          },
          {
            path: ":publicationId",
            element: <ReadPublication />,
          },
        ],
      },
      {
        path: "statistics",
        element: <Statistics />,
      },
      {
        path: "training-centers",
        element: <TrainingCenters />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "seminarsTraining",
        children: [
          {
            index: true,
            element: <SeminarsTraining />,
          },
          {
            path: ":seminaireId",
            element: <ReadSeminarsTraining />,
          },
        ],
      },
    ]
  },
]);

function App() {
  return <RouterProvider router={router} />;
  
}

export default App;
