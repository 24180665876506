import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.jpg";

import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <div>
      <footer className="bg-custom-blue-rgba dark:bg-gray-900">
        <div className="w-full max-w-screen-3xl mx-auto p-4">
          <div className="sm:flex sm:items-center sm:justify-between">
            <Link to="/" className="flex items-center mb-4 sm:mb-0">
              <img src={logo} className="h-8 mr-3" alt="SIMT" />
              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                SIMT
              </span>
            </Link>
            <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-black-500 sm:mb-0 dark:text-gray-400">
              <li>
                <Link to="/about" className="mr-4 hover:underline md:mr-6 ">
                  {t("header.navbar.about")}
                </Link>
              </li>
              <li>
                <Link to="/statistics" className="mr-4 hover:underline md:mr-6">
                  {t("header.navbar.statistics")}
                </Link>
              </li>
              <li>
                <Link
                  to="/publications"
                  className="mr-4 hover:underline md:mr-6 "
                >
                  {t("header.navbar.publications")}
                </Link>
              </li>
              <li>
                <Link to="/training-centers" className="hover:underline">
                  {t("header.navbar.seminars-and-training")}
                </Link>
              </li>
            </ul>
          </div>
          <hr className="my-6 border-yellow-400 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <span className="block text-sm text-black-500 sm:text-center dark:text-gray-400">
            © 2023{" "}
            <a href="/" className="hover:underline">
              SIMT
            </a>
            . Tous droits réservés.
          </span>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
