import React from "react";

import MostViewed from "../../components/Publications/mostViewed/fromApi";
import ReadPublicationComp from "../../components/Publications/ReadPublication";
import { useTranslation } from "react-i18next";

function ReadPublication() {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen">
      {/* <div className='pt-5'>
            <h1 className='text-3xl font-bold leading-none tracking-tight text-gray-900 md:text-4xl lg:text-5xl dark:text-white text-center'>
                Publications
            </h1>
        </div> */}
      <div className="w-full pl-8 pr-12 grid grid-rows-1 lg:grid-cols-3 gap-4">
        <div className="mt-5 w-full col-span-3 lg:col-span-2">
          <div
            className="text-3xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl lg:text-5xl dark:text-white 
                    text-center"
          >
            {t("page.publication.publication")}
          </div>
        </div>
        <div className="col-span-3 lg:col-span-2 p-4">
          <ReadPublicationComp />
        </div>
        <aside className="w-full col-span-3 lg:col-span-1">
          <MostViewed />
        </aside>
      </div>
    </div>
  );
}

export default ReadPublication;
