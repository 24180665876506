export const getUrl = (base64ImageString) => {
  try {
    //Convert Base64 string to byte array (Uint8Array)
    const binaryData = atob(base64ImageString);
    const arrayBuffer = new Uint8Array(binaryData.length);
    
    for (let i = 0; i < binaryData.length; i++) {
      arrayBuffer[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: 'image/*' });

    const url = URL.createObjectURL(blob);

    return url;
  } catch (error) {
    throw error;
  }
};
