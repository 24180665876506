import React from 'react'
import CenterComp from '../../components/Center';


function TrainingCenters() {
  return (
    <CenterComp />
  )
}

export default TrainingCenters;