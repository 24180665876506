import React from "react";

import Aside from "../../components/Aside";
import ReadSeminarsTrainingComp from "../../components/Seminars/ReadSeminarsTraining";
function ReadSeminarsTraining() {
  return (
    <div className="min-h-screen">
      {/* <div className='pt-5'>
            <h1 className='text-3xl font-bold leading-none tracking-tight text-gray-900 md:text-4xl lg:text-5xl dark:text-white text-center'>
                Publications
            </h1>
        </div> */}
      <div className="w-full pl-8 pr-12 grid grid-rows-1 lg:grid-cols-3 gap-4">
        <div className="mt-5 w-full col-span-3 lg:col-span-2">
          <div
            className="text-3xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl lg:text-5xl dark:text-white 
                    text-center"
          >
            Séminaire ou Formation
          </div>
        </div>
        <div className="col-span-3 lg:col-span-2 p-4">
          <ReadSeminarsTrainingComp />
        </div>
        <aside className="w-full col-span-3 lg:col-span-1">
          <Aside />
        </aside>
      </div>
    </div>
  );
}

export default ReadSeminarsTraining;
