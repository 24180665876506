import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Statistics() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  const menuOpenHandler = () => {
    setIsMenuOpen(true);
  };

  const menuCloseHandler = (e) => {
    setIsMenuOpen(false);
  };

  return (
    <div
      className="relative"
      onMouseEnter={menuOpenHandler}
      onMouseLeave={menuCloseHandler}
    >
      <div className="flex items-center">
        <NavLink
          to="/statistics"
          className={({ isActive }) =>
            isActive
              ? `text-[#ff8c00] text-xs md:text-sm block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 
            md:hover:text-[#ff8c00] md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white
            md:dark:hover:bg-transparent`
              : `block py-2 pl-3 pr-4 text-gray-900 text-xs md:text-sm  rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 
            md:hover:text-[#ff8c00] md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white
            md:dark:hover:bg-transparent`
          }
        >
          {t("header.navbar.statistics")}
        </NavLink>
        <ChevronDownIcon
          strokeWidth={1.5}
          className={`h-3 w-3 transition-transform ml-2 ${
            isMenuOpen ? "rotate-180" : ""
          }`}
        />
      </div>
      {isMenuOpen && (
        <div className="absolute top-full mt-0 left-0 z-10 py-2 w-48 bg-white rounded-lg shadow-xl border border-solid border-gray-500">
          <NavLink
            to="/statistics/#repGeoEmploi"
            className="block px-4 py-2 text-gray-800  text-xs md:text-smhover:bg-indigo-500 hover:text-[#ff8c00] "
          >
            Repartition Geographique de l'emploi
          </NavLink>
          <NavLink
            to="/statistics/#rep-emp-sector"
            className="block px-4 py-2 text-gray-800  text-xs md:text-smhover:bg-indigo-500 hover:text-[#ff8c00]"
          >
            Répartition des offres d'emploi par secteur
          </NavLink>
          <NavLink
            to="/statistics/#rep-domaine"
            className="block px-4 py-2 text-gray-800  text-xs md:text-smhover:bg-indigo-500 hover:text-[#ff8c00]"
          >
            Répartition des offres d'emploi par domaine
          </NavLink>
          <NavLink
            to="/statistics/#rep-formation"
            className="block px-4 py-2 text-gray-800  text-xs md:text-smhover:bg-indigo-500 hover:text-[#ff8c00]"
          >
            Repartition Géographique des offres de formations
          </NavLink>
        </div>
      )}
    </div>
  );
}

//   export default function Statistics() {
//     const [isMenuOpen, setIsMenuOpen] = useState(false);
//     const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

//     const isMenObileOpenHandler = () => {
//       setIsMobileMenuOpen((cur) => !cur);
//       setIsMenuOpen(() => (!isMenuOpen));
//     };

//       return (
//         <div className="relative">
//             <button
//               className="flex items-center gap-2 py-2 pr-3"
//               selected={isMenuOpen || isMobileMenuOpen}
//               onClick={isMenObileOpenHandler}
//             >
//                 Trier par
//                 <ChevronDownIcon
//                   strokeWidth={1.5}
//                   className={`hidden h-3 w-3 transition-transform lg:block ${
//                     isMenuOpen ? "rotate-180" : ""
//                   }`}
//                 />
//                 <ChevronDownIcon
//                   strokeWidth={1.5}
//                   className={`block h-3 w-3 transition-transform lg:hidden ${
//                     isMobileMenuOpen ? "rotate-180" : ""
//                   }`}
//                 />
//             </button>
//             {isMenuOpen && (
//               <div className="absolute top-full left-0 z-10 mt-2 py-2 w-48 bg-white rounded-lg shadow-xl">
//                 <NavLink to="#" className="block px-4 py-2 text-gray-800  text-xs md:text-smhover:bg-indigo-500 hover:text-white">Videos</NavLink>
//                 <NavLink to="#" className="block px-4 py-2 text-gray-800  text-xs md:text-smhover:bg-indigo-500 hover:text-white">Audios</NavLink>
//                 <NavLink to="#" className="block px-4 py-2 text-gray-800  text-xs md:text-smhover:bg-indigo-500 hover:text-white">Images</NavLink>
//               </div>
//             )}
//         </div>
//       );
//   }
