import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';

function PieChart(props) {
  const chartContainer = useRef(null);
  let typeChart = 'pie';

  useEffect(() => {
    let chartInstance = null;
    
    if (chartContainer && chartContainer.current) {
      if (chartInstance) {
        chartInstance.destroy();
      }

      chartInstance = new Chart(chartContainer.current, {
        type: 'pie',
        data: {
          labels: props.labels,
          datasets: [{
            label: '_',
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'rgb(128, 0, 128)',
                'rgb(0, 128, 0)',
                'rgb(255, 0, 0)',
                'rgb(0, 0, 255)',
                'rgb(128, 128, 0)',
                'rgb(0, 128, 128)',
                'rgb(192, 192, 192)',
                'rgb(255, 165, 0)',
                'rgb(255, 0, 255)',
                'rgb(0, 255, 255)',
                'rgb(128, 0, 0)',
                'rgb(0, 128, 128)',
                'rgb(0, 0, 128)',
                'rgb(0, 255, 0)',
                'rgb(128, 128, 128)',
                'rgb(128, 128, 255)',
                'rgb(128, 0, 128)'
              ],
              
            data: props.data,
            
          }]
        },
        options: {
          plugins: {
            legend: {
                display: true,
                position: 'left',
                labels: {
                    color: 'rgb(255, 99, 132)'
                }
            }
        }
          
        }
      });
    }
  
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [chartContainer, props]);
  

  return (
    <div className="w-full" style={{position: 'relative', height: '60vh'}} >
      <canvas className='w-full' ref={chartContainer} />
    </div>
  );
}

export default PieChart;
