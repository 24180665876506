import React, { useState, useEffect } from "react";
import CentreCard from "../../Centre/CentreCard";
import haitimap from "../../../assets/images/haitimap.png";
import Title from "../../Title";
import VoirPlusButton from "../../VoirPlusButton";
import { List } from "../../Seminars/list";
import Pagination from "../../Seminars/pagination";
import Queries from "../../../utils/queries";
import Mapping from "../../../utils/mapping";
import { useTranslation } from "react-i18next";
import Error from "../../Error/index";

function Seminars() {
  const [seminars, setSeminars] = useState([]);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const seminars = async () => {
      try {
        const response = await Queries.lastSeminars();
        const seminarsFetched = Mapping.seminars(response.data);
        setSeminars(seminarsFetched);
      } catch (error) {
        if (error.response) {
          console.log(`error.response`);
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          setError(t("error.error-request"));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        // console.log(error.toJSON());
      }
    };
    seminars();
  }, []);
  return (
    <section
      role="region"
      aria-labelledby="latest_seminars"
      className={() => (error ? "bg-gray-50" : "min-h-screen  bg-gray-50")}
    >
      <div className="max-w-full pl-1 lg:pl-4 pr-1 lg:pr-4 grid grid-rows-1 lg:grid-cols-5 gap-4 md:grid-cols-5">
        <div className="col-span-5 md:col-span-5 md:p-4">
          <div className="grid grid-cols-1 lg:grid-cols-4">
            <div className="col-span-4 pr-10 pl-4">
              <Title
                name={t("page.home.latesttraining")}
                id="latest_seminars"
              />
              {error ? (
                <Error
                  titre={t("error.error-request")}
                  indication={t("error.loadingIndication")}
                />
              ) : seminars.length === 0 ? (
                <>
                  <p className="text-center">Aucun séminaire trouvé</p>
                </>
              ) : (
                <>
                  <List seminars={seminars} />
                  <div className="w-full flex items-center justify-center">
                    <VoirPlusButton link="/seminarsTraining" tabIndex={0} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Seminars;
