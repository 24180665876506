import React, { useState, useEffect } from "react";
import MarketExploration from "../../components/Home/MarketExploration";
import LastestPublications from "../../components/Home/LastestPublications";
import Statistics from "../../components/Home/Statistics";
import About from "../../components/Home/About";
import Seminars from "../../components/Home/Seminars";
import Center from "../../components/Home/Center";
import Queries from "../../utils/queries";
import Mapping from "../../utils/mapping";
import { useTranslation } from "react-i18next";

function Home() {
  const [centres, setCentres] = useState([]);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const visited = async () => {
      try {
        const visite = await Queries.visite("acceuil");
      } catch (error) {}
    };
    visited();
  });

  useEffect(() => {
    const fetchCenters = async () => {
      try {
        const visite = await Queries.visite("acceuil");
        const response = await Queries.lastTrainingCenter();
        const centersFetched = Mapping.centres(response.data);
        setCentres(centersFetched);
      } catch (error) {
        // Gérer les erreurs de manière appropriée
        setError(t("error.error-request"));
        console.error("Error fetching centers:", error);
      }
    };
    fetchCenters();
  }, []);

  const [info, setAboutInfo] = useState({});

  useEffect(() => {
    const about = async () => {
      try {
        const response = await Queries.apropos();
        setAboutInfo(response.data);
      } catch (error) {}
    };
    about();
  }, []);

  return (
    <main className="bg-gray-50">
      <MarketExploration info={info} />
      <LastestPublications />
      <Statistics />
      <About info={info} />
      <Seminars />
      <Center centres={centres} />
    </main>
  );
}

export default Home;
