import React from "react";
import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import subvention from "../../../../assets/images/subvention.png";
import prive from "../../../../assets/images/prive.png";
import publique from "../../../../assets/images/publique.png";
import centre from "../../../../assets/images/centre.png";
import styled from "styled-components";

const Label = styled.span`
  display: inline-block;
  width: 150px;
  fontweight: bold;
`;

const Value = styled.span`
  display: inline;
`;
function DomaineItem({ domaine }) {
  return <span>{domaine.description}</span>;
}

function DomainesList({ domaines }) {
  return (
    <span>
      {domaines.map((domaine) => (
        <DomaineItem key={domaine.domaineId} domaine={domaine} />
      ))}
    </span>
  );
}

function VerticalCard({ publication, toggleFilter }) {
  const [openModal, setOpenModal] = useState(false);
  function formatDate(inputDateString) {
    const inputDate = new Date(inputDateString);

    if (isNaN(inputDate)) {
      return "Invalid Date";
    }

    const day = inputDate?.getDate()?.toString().padStart(2, "0");
    const month = (inputDate?.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are zero-based, so we add 1.
    const year = inputDate?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  return (
    <article>
      <button
        onClick={() => {
          setOpenModal(true);
          toggleFilter();
        }}
        className="max-w-sm border-b-4 border-b-orange-400 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 lg:h-[300px] sm: h-[330px]
            transform transition-all hover:scale-95 cursor-pointer"
      >
        {publication.type == "privé" && (
          <img className="rounded-t-lg" src={prive} alt="" />
        )}
        {publication.type == "public" && (
          <img className="rounded-t-lg" src={publique} alt="" />
        )}
        {publication.type == "subve" && (
          <img className="rounded-t-lg" src={subvention} alt="" />
        )}
        {!publication.type && (
          <img className="rounded-t-lg" src={centre} alt="" />
        )}

        {/* </Link> */}
        <div className="p-3">
          <p className="mb-2 text-sm font-bold tracking-tight text-gray-900 dark:text-white">
            {publication.designation}{" "}
            {publication.sigle && "(" + publication?.sigle + ")"}
          </p>

          <p className="mb-3 font-normal text-xs text-gray-700 dark:text-gray-400">
            <b>Adresse:</b> {publication?.adresse}
            <br />
            <b>Telephone:</b> {publication.telephone1} /{" "}
            {publication.telephone1} <br />
            <b>Domaines:</b> <DomainesList domaines={publication?.domaines} />{" "}
            <br />
          </p>
        </div>
      </button>

      <Modal
        show={openModal}
        onClose={() => {
          setOpenModal(false);
          toggleFilter();
        }}
      >
        <Modal.Header>
          {" "}
          {publication.designation}{" "}
          {publication.sigle && "(" + publication?.sigle + ")"}
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <Label>Adresse:</Label>
              <Value>{publication.adresse} </Value> <br />
              <Label>Téléphone:</Label>
              <Value>
                {publication.telephone} / {publication?.telephone1} /{" "}
                {publication.telephone2}{" "}
              </Value>{" "}
              <br />
              <Label>Responsable:</Label>
              <Value>{publication.responsable} </Value> <br />
              <Label>Email:</Label>
              <Value>{publication.email} </Value> <br />
              <Label>Descriptif:</Label>
              <Value>{publication.descriptif} </Value> <br />
              <Label>Adresse:</Label>
              <Value>{publication.adresse} </Value> <br />
              <Label>Domaine:</Label>
              <Value>
                <DomainesList domaines={publication?.domaines} />
              </Value>{" "}
              <br />
              <Label>Type:</Label>
              <Value>{publication.type} </Value> <br />
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setOpenModal(false);
              toggleFilter();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </article>
  );
}

export default VerticalCard;
