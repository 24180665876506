import React, { useState, useEffect } from "react";
import Title from "../Title";
import StackedBarChart from "../StackedBarChart";
import DataTable from "../DataTable";
import BarChart from "../charts/BarChart";
import PieChart from "../charts/PieChart";
import Subtitle from "../Subtitle";
import Loading from "../Loader/index";

const RepEmploiProfession= (props) => {
const stats = props.statistiques.repEmploiProfession || []
  const data = stats.map(item => [item.label, item.nombre]);

  data.sort((a, b) => b[1] - a[1]);
  const top10 = data.slice(0, 10);
  // Calcul de la somme des valeurs des éléments qui ne sont pas dans le top 10
  const autresData = data.slice(10);
  const autresValue = autresData.reduce((acc, current) => acc + current[1], 0);
  
  // Création de l'étiquette "Autres" en fonction du nombre d'éléments inclus
  const autresLabel = `${autresData.length} Autres`;
  
  // Ajout de l'élément "Autres" avec la valeur calculée au tableau top10
  top10.push([autresLabel, autresValue]);

  const topKeyValue = top10.reduce((obj, [key, value]) => {
    obj[key] = value;
    return obj;
  }, {});

  const headers = ["Professions", "Nombre d'offre"];

  return (
    <>
     

      <Title name="Répartition des offres par professions" />
      <div className="mx-auto grid grid-cols-1 lg:grid-cols-2 gap-4">
        { 
        props.isLoading ?
        <Loading />
        :<>
        <div>
          <Subtitle name="Top 10 des professions les plus demandés" />
          
          <PieChart
          type="bar"
            labels={Object.keys(topKeyValue)}
            data={Object.values(topKeyValue)}
          />
        </div>
        <div>
          <DataTable
            data={data}
            headers={headers}
          />
        </div>
        </>
        }
        
      </div>
    </>
  );
};

export default RepEmploiProfession;
