import React from 'react';
import PageContent from '../../components/PageContent';
import { useRouteError } from "react-router-dom";

function Error() {
    const error = useRouteError();

    let title = 'An error occurred!';
    let message = 'Something went wrong!';
    console.log( "Here!");

    if ( error.status === 500 ){
        message = JSON.parse(error.data).message;
    }

    if ( error.status === 400 ){
        title = 'Not found !'
        message = 'Could not found resource or page';
    }
  return (
    <PageContent title={title}>
        <p>{message}</p>
    </PageContent>
  )
}

export default Error;