import { NavLink } from "react-router-dom";

const activeStyle = `text-custom-orange block py-2 pl-3 pr-4 text-xs lg:text-sm  rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 
lg:hover:text-[#ff8c00] lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white
lg:dark:hover:bg-transparent`;

const inactiveStyle = `block py-2 pl-3 pr-4 text-xs lg:text-sm  text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 
lg:hover:text-custom-orange lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white
lg:dark:hover:bg-transparent`;

export default function NavItem({ to, children }) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
    >
      {children}
    </NavLink>
  );
}
