import React, { useState, useEffect } from "react";
import Title from "../Title";
import DataTable from "../DataTable";
import PieChart from "../charts/PieChart";
import Subtitle from "../Subtitle";
import Loading from "../Loader/index";

const RepMainOeuvreCompetence = (props) => {
  const stats = props.statistiques.repGeoMainOeuvreCompetence || [];
  const data = stats
    .filter((item) => item !== null)
    .map((item) => [item.label, item.nombre]);

  data.sort((a, b) => b[1] - a[1]);
  const top10 = data.slice(0, 10);
  // Calcul de la somme des valeurs des éléments qui ne sont pas dans le top 10
  const autresData = data.slice(10);
  const autresValue = autresData.reduce((acc, current) => acc + current[1], 0);

  // Création de l'étiquette "Autres" en fonction du nombre d'éléments inclus
  const autresLabel = `${autresData.length} Autres`;

  // Ajout de l'élément "Autres" avec la valeur calculée au tableau top10
  top10.push([autresLabel, autresValue]);

  const topKeyValue = top10.reduce((obj, [key, value]) => {
    obj[key] = value;
    return obj;
  }, {});

  const headers = ["Compétence", "Nombre offres"];

  return (
    <>
      <Title name="Répartition de la main d'oeuvre par compétence" />
      <div className="mx-auto grid grid-cols-1 lg:grid-cols-2 gap-4">
        {props.isLoading ? (
          <Loading />
        ) : (
          <>
            <div>
              <Subtitle name="Top 10 des compétences ayant le plus d'offres" />

              <PieChart
                type="bar"
                labels={Object.keys(topKeyValue)}
                data={Object.values(topKeyValue)}
              />
            </div>
            <div>
              <DataTable data={data} headers={headers} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RepMainOeuvreCompetence;
