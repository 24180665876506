import { useLocation } from "react-router-dom";
import { useState } from "react";

export default function () {
  const location = useLocation();
  const state = location.state || {};
  const [selectedDepartement, setSelectedDepartement] = useState(
    state.departement || null
  );
  const [selectedVille, setSelectedVille] = useState(state.ville || null);
  const [selectedDomaine, setSelectedDomaine] = useState(state.domaine || null);
  const [selectedSecteur, setSelectedSecteur] = useState(state.secteur || null);

  return {
    selectedDepartement,
    selectedDomaine,
    selectedSecteur,
    selectedVille,
    setSelectedDepartement,
    setSelectedDomaine,
    setSelectedSecteur,
    setSelectedVille,
  };
}
