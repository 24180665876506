import React from "react";
import HorizontalCard from "./horizontalCard";

import { truncateText } from "../../utils/texts/truncate";
import Substitle from "../Subtitle";

function Aside({ title, items, config }) {
  return (
    <aside className="">
      <section>
        <Substitle name={title} />
        {items.map((e) => (
          <div className="m-4" key={e[config.key]}>
            <HorizontalCard
              title={truncateText(e[config.title], 35)}
              description={truncateText(e[config.description], 100)}
              image={e[config.image]}
              to={`/publications/${e[config.key]}`}
            />
          </div>
        ))}
      </section>
    </aside>
  );
}

export class asideConfiguration {
  constructor(titre, description, image, key) {
    this.title = titre;
    this.description = description;
    this.image = image;
    this.key = key;
  }
}

export default Aside;
